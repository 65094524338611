import { Checkbox, FormControlLabel, FormGroup, Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { updateClientInfo } from '../../../services';
import { BottomSaveBar, ClientDiscountList } from '../components';


const ClientDiscountSettings = () => {
    const theme = useTheme();
    const clientInfo = useAppSelector(state => state.clientInfo.data);
    const dispatcher = useAppDispatch();
    const [dmanager, setDmanager] = useState(clientInfo.discountManager);

    const updateDiscountSettings = async () => {
        try {
            const tempClient = { ...clientInfo };
            tempClient.discountManager = {
                ...tempClient.discountManager,
                allowDefaultDiscount: dmanager.allowDefaultDiscount,
                allowDiscountOnTotalBill: dmanager.allowDiscountOnTotalBill,
                considerStrictProductSettings: dmanager.considerStrictProductSettings,
                isDiscountsApplicable: dmanager.isDiscountsApplicable,
            };
            await dispatcher(updateClientInfo({
                clientId: tempClient._id,
                body: tempClient
            }));
        } catch (e) {
            console.error(e);
        }
    }

    const initialLoad = async () => {
        try {
            // setSelectedOption(selectedOption);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        initialLoad();
    }, []);

    return (
        <Stack p={2}>

            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={dmanager.isDiscountsApplicable}
                            onChange={(e) => setDmanager({ ...dmanager, isDiscountsApplicable: e.target.checked })}
                        />
                    }
                    label="Enable Discounts"
                />
                <Stack ml={5}>

                    <FormGroup >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!dmanager.isDiscountsApplicable}
                                    checked={dmanager.allowDiscountOnTotalBill}
                                    onChange={(e) => setDmanager({ ...dmanager, allowDiscountOnTotalBill: e.target.checked })}
                                />
                            }
                            label={
                                <span>
                                    Allow Discount on total Bill
                                    <br />
                                    <small>Applies a discount on entire bill and shows option to 
                                        give discount while billing.</small>
                                </span>
                            }

                        />
                        {/* TODO Enable this feature in VERSION3 */}
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!dmanager.isDiscountsApplicable}
                                    checked={dmanager.allowDefaultDiscount}
                                    onChange={(e) => setDmanager({ ...dmanager, allowDefaultDiscount: e.target.checked })}
                                />
                            }
                            label={
                                <span>
                                    Allow default discount
                                    <br />
                                    <small>Applies default discounton the total bill.</small>
                                </span>
                            }
                        /> */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!dmanager.isDiscountsApplicable}
                                    checked={dmanager.considerStrictProductSettings}
                                    onChange={(e) => setDmanager({ ...dmanager, considerStrictProductSettings: e.target.checked })}
                                />
                            }
                            label={
                                <span>
                                    Allow Product Secific Discounts
                                    <br />
                                    <small>Overrides global discounts with product-specific settings</small>
                                </span>
                            }
                        />
                    </FormGroup>

                </Stack>
            </FormGroup>
            <ClientDiscountList />
            <BottomSaveBar onSave={updateDiscountSettings} />
        </Stack>
    );
};

export { ClientDiscountSettings };

