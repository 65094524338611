import { ClientInfoType } from "../../../all-types";

export const CLEINT_INFO_INITIAL_DATA:ClientInfoType = {
  _id: "",
  clientID: "",
  clientName: "",
  clientLogo: "",
  PhoneNumber: 0,
  email: "",
  gstNo: "",
  fssaiNo: "",
  address: {
    shopNo: "",
    buildingName: "",
    area: "",
    city: "",
    state: "",
    landmark: "",
    fullAddress: "",
    pincode: 0,
    country: "",
    coords: {
      latitude: 0,
      longitude: 0,
    },
  },
  printerSettings: {
    name: "",
    productId: "",
    vendorId: "",
    connectionType: "",
    driverInfo: "",
    printerLocation: "",
    isPrinterConfigured: false,
    connectionSettings: {
      productId: "",
      vendorId: "",
      remoteHostIp: "",
      remoteHostPort: "",
    },
  },
  cardReaderSettings: {
    paytm: {
      mid: "",
      tid: "",
      channelId: "",
    },
  },
  taxManager: {
    isTaxApplicable: true,
    applyDefaultTax: true,
    considerStrictProductSettings: true,
    activeTaxList: {},
    deactiveTaxList: {}
  },
  discountManager: {
    isDiscountsApplicable: true,
    allowDiscountOnTotalBill: true,
    allowDefaultDiscount: true,
    considerStrictProductSettings: true,
    activeDiscounts: {},
    deactiveDiscounts: {}
  },
  tableManager: {
    noOffloors: 1,
    places: [],
    tables: {}
  }
};
