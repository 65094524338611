import AnalyticsIcon from '@mui/icons-material/Analytics';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import InventoryIcon from '@mui/icons-material/Inventory';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button, Divider, Tab, Tabs, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';
import { STYLE_CONSTANTS } from '../constants';

//const drawerWidth = 150;

const sidebarUrls: {
  [key: string]: { url: string; icon: JSX.Element; name: string };
} = {
  Home: {
    url: '/app',
    name: 'Home',
    icon: <HomeIcon sx={{ fontSize: 30 }} />,
  },
  Dashboard: {
    url: '/app',
    name: 'Dashboard',
    icon: <DashboardIcon sx={{ fontSize: 30 }} />,
  },
  Order: {
    url: '/app/order',
    name: 'Order',
    icon: <BookmarkBorderIcon sx={{ fontSize: 30 }} />,
  },
  Inventory: {
    url: '/app/inventory',
    name: 'Inventory',
    icon: <InventoryIcon sx={{ fontSize: 30 }} />,
  },
  Category: {
    url: '/app/category',
    name: 'Category',
    icon: <CategoryIcon sx={{ fontSize: 30 }} />,
  },
  Settings: {
    url: '/app/settings',
    name: 'Settings',
    icon: <SettingsIcon sx={{ fontSize: 30 }} />,
  },
  // Reports: {
  //   url: '/app/reports',
  //   name: 'Reports',
  //   icon: <AnalyticsIcon sx={{ fontSize: 30 }} />,
  // },
  Business: {
    url: '/app/business',
    name: 'Business',
    icon: <WorkIcon sx={{ fontSize: 40 }} />,
  }
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: STYLE_CONSTANTS.DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0
  //width: `calc(${theme.spacing(7)} + 1px)`,
  // [theme.breakpoints.up('sm')]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: STYLE_CONSTANTS.DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface SideBarProps {
  open: boolean;
  handleDrawerClose: () => void;
}

const SideBar: FC<SideBarProps> = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    const key = Object.keys(sidebarUrls)[newValue];
    navigate(sidebarUrls[key].url);
  };

  return (
    <Drawer variant="permanent" open={open} >
      <DrawerHeader >
        <Box justifyContent={'center'} alignItems={'center'} display={'flex'} gap={1} onClick={() => handleDrawerClose()}>
          <Typography variant="caption">Lightwork POS</Typography>
        </Box>
      </DrawerHeader>
      <Divider />
      <Tabs
        orientation="vertical"
        value={selectedTab}
        onChange={handleTabChange}
      >
        {Object.keys(sidebarUrls).map((key, index) => (
          <Tab
            key={key}
            icon={sidebarUrls[key].icon}
            label={sidebarUrls[key].name}
            iconPosition="top"
            onClick={() => navigate(sidebarUrls[key].url)}
            sx={{
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.CommonWhite.main,
              //borderRadius: 2,
              minHeight: STYLE_CONSTANTS.SIDEBAR_TILE_SIZE,
              //mt: '5px',
              '&.Mui-selected': {
                margin: 2,
                borderRadius: 3,
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
                boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.2)"
              },
            }}
          />
        ))}
      </Tabs>
      <Divider />
      <Box flex={1} display={'flex'} justifyContent={'center'} alignItems={'flex-end'}>
        <Button>v2.0.0</Button>
      </Box>
    </Drawer>
  );
};

export default SideBar;
