import * as React from 'react';
import { styled, Theme, useTheme } from '@mui/material';
import { Box, Typography } from '@mui/material';
import PaymentsIcon from '@mui/icons-material/Payments';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { updateBill } from '../../../redux-store';


interface PaymentOptionProps {
    selected: boolean;
}

const PaymentOption = styled(Box) <PaymentOptionProps>`
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${({ selected }) => (selected ? '#f5e8e4' : '#f5f5f5')};
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s;
  border: ${({ selected }) => (selected ? '2px solid #a0522d' : 'none')};
`;

interface PaymentMethodProps {
    currentTab: string
}

const MODE_OF_PAYMENT = {
    CASH: "CASH",
    CARD: "CARD",
    UPI: "UPI"
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({ currentTab }) => {
    const theme = useTheme();
    const dispatcher = useAppDispatch();
    const bill = useAppSelector(state => state.billing.activeBills[currentTab].bill);

    const updatePaymentMethod = (paymentMode: string) => {
        try {
            dispatcher(updateBill({ id: currentTab, bill: { ...bill, modeOfPayment: paymentMode } }));
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                //justifyContent: 'flex-start',
                //alignItems: 'flex-end',
                flexDirection: "column",
                width: "100%",
                //height: '100vh',
                margin: 0,
            }}
        >
            <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: 2 }}>
                Payment Method
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <PaymentOption selected={bill.modeOfPayment === MODE_OF_PAYMENT.CASH} onClick={() => updatePaymentMethod(MODE_OF_PAYMENT.CASH)}>
                    <PaymentsIcon />
                    <Typography>Cash</Typography>
                </PaymentOption>
                <PaymentOption selected={bill.modeOfPayment === MODE_OF_PAYMENT.CARD} onClick={() => updatePaymentMethod(MODE_OF_PAYMENT.CARD)}>
                    <CreditCardIcon />
                    <Typography>Card</Typography>
                </PaymentOption>
                <PaymentOption selected={bill.modeOfPayment === MODE_OF_PAYMENT.UPI} onClick={() => updatePaymentMethod(MODE_OF_PAYMENT.UPI)}>
                    <AddToHomeScreenIcon />
                    <Typography>UPI</Typography>
                </PaymentOption>
            </Box>
        </Box>
    );
};

export { PaymentMethod };