import { useMemo, useState } from 'react'
import { BillItem, ProductType } from '../all-types'
import { useAppSelector } from '../redux-store/hooks'
import { ProductDiscountCalc, ProductTaxCalc } from '../screens'

const useProductSettings = (product: ProductType, record: BillItem) => {
  const [billRecord, setBillRecord] = useState<BillItem>(record);

  const taxSettings = useAppSelector(
    (state) => state.clientInfo.data.taxManager
  )
  const discountSettings = useAppSelector(
    (state) => state.clientInfo.data.discountManager
  )

  const calculatedBillrecord = useMemo(() => {
    if (billRecord.amount !== 0) {
      const productDiscountCalc = new ProductDiscountCalc(
        discountSettings,
        product.discounts
      );
      productDiscountCalc.applyDiscountByProductSettings(billRecord.amount);
      const productTaxCalc = new ProductTaxCalc(taxSettings, product.tax);
      const amountForTax = productDiscountCalc.billDiscount.isDiscountApplied ? 
      productDiscountCalc.billDiscount.amountAfterDiscount : billRecord.amount;
      productTaxCalc.applyProductTax(amountForTax);
      const TOTAL_DISCOUNT = productDiscountCalc.billDiscount.discountAmount;
      const TOTAL_TAX = productTaxCalc.billTax.taxAmount;
      setBillRecord({...billRecord, billingInfo: {
        ...billRecord.billingInfo,
        discounts: productDiscountCalc.billDiscount,
        tax: productTaxCalc.billTax,
        rawAmount: billRecord.amount,
        totalDiscountGiven: TOTAL_DISCOUNT,
        totalTaxCharged: TOTAL_TAX,
        finalAmount: ((billRecord.amount - TOTAL_DISCOUNT) + TOTAL_TAX)
      }});
    }
  }, [billRecord.amount])

  return { billRecord, setBillRecord }
}

export { useProductSettings }
