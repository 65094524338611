import { useEffect, useState } from "react";
import { getOfflineStoreData, OfflineStores } from "../offline-db";
import { loadActiveBills, OfflineBill } from "../redux-store";
import { useAppDispatch } from "../redux-store/hooks";
import { getClientInfo } from "../services";
import { AppConfig } from "../config";


const useStartupLoader = () => {
    const [message, setMessage] = useState("loading...");
    const dispatcher = useAppDispatch();

    const loadActiveBillsfromOfflineDB = async() => {
        try {
            setMessage("loading offline active sessions...");
            const activeBills:OfflineBill[] = await getOfflineStoreData(OfflineStores.ActiveBills);
            const activeBillsObj:{[sessionId: string] : OfflineBill} = 
            activeBills.reduce((acc: {[sessionId: string] : OfflineBill}, val) => {
                acc[val.sessionId] = val; return acc
            }, {} );
            dispatcher(loadActiveBills(activeBillsObj));
        } catch(e) {
            console.error(e);
        }
    }

    const loadOfflineData = async() => {
        try {
            setMessage("loading offline data...");
            const isDbInitialized = sessionStorage.getItem('isDbReady');
            if(isDbInitialized) {
                await loadActiveBillsfromOfflineDB();
            }
        } catch(e) {
            console.error(e);
        }
    }


    const loadAll = async() => {
        try {
            await loadOfflineData();
            dispatcher(getClientInfo({ clientId: AppConfig.getCLientId() || ""}));
        } catch(e) {
            console.error(e);
        }
    }

    
    useEffect(() => {
        setTimeout(async () => {
            await loadAll();
        }, 2000);
    }, [dispatcher]);


    return {message};
}

export {useStartupLoader};