const COMMON_STYLES = {
  CHAIR_SIZE: 80,
  TABLE_MIN_WIDTH: 200,
  TABLE_HEIGHT: 200,
  TABLE_NUMBER_FONT_SIZE: 45,
  OTHER_FONT_SIZE: 16,
  TABLE_CHAIR_DISTANCE: 20,
}

export const getStylesBySize = (size: string) => {
  switch (size) {
    case 'SMALL':
      COMMON_STYLES.CHAIR_SIZE = 40
      COMMON_STYLES.TABLE_MIN_WIDTH = 100
      COMMON_STYLES.TABLE_HEIGHT = 100
      COMMON_STYLES.TABLE_NUMBER_FONT_SIZE = 20
      COMMON_STYLES.TABLE_CHAIR_DISTANCE = 10
      COMMON_STYLES.OTHER_FONT_SIZE = 10
      return COMMON_STYLES
    case 'MEDIUM':
      COMMON_STYLES.CHAIR_SIZE = 50
      COMMON_STYLES.TABLE_MIN_WIDTH = 120
      COMMON_STYLES.TABLE_HEIGHT = 120
      COMMON_STYLES.TABLE_NUMBER_FONT_SIZE = 30
      COMMON_STYLES.TABLE_CHAIR_DISTANCE = 15
      COMMON_STYLES.OTHER_FONT_SIZE = 12
      return COMMON_STYLES
    case 'LARGE':
      return COMMON_STYLES
    default:
      return COMMON_STYLES
  }
}
