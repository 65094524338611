import styled from "@emotion/styled";
import { Button } from "@mui/material";


export const LargeBtn = styled(Button)({
    minWidth: '200px', // Adjust the width as needed
    height: '100px', // Adjust the height as needed
    borderRadius: 2,
    fontSize: '18px',
    fontWeight: 'bold',
    padding: '10px 20px',
    flexDirection:"column"
  });