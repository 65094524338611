import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { CommonBtn } from '../../../components';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface BillConfimPopupProps {
    open: boolean;
    handleClose: () => void;
    onConfirm: () => void;
}

const BillConfimPopup: React.FC<BillConfimPopupProps> = ({ open, handleClose, onConfirm }) => {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack gap={2}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Would you like to Proceed ?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        this will create the bill and close the table/Session
                    </Typography>
                    <Stack flexDirection={"row"} gap={1}>
                        <CommonBtn variant='contained' color='error' onClick={handleClose}> Cancel</CommonBtn>
                        <CommonBtn variant='contained' onClick={onConfirm}> Confirm</CommonBtn>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export { BillConfimPopup }