import { BottomNavigation, Button, Stack } from "@mui/material"

interface BottomSaveBarProps {
    onSave: () => void;
    childrenActions?: React.ReactNode
}

const BottomSaveBar: React.FC<BottomSaveBarProps> = ({ onSave, childrenActions }) => {
    return (
        <BottomNavigation showLabels sx={{
            position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1, justifyContent: "flex-end", height: 100
        }}>
            <Stack sx={{
                width: "100%", height: "100%", 
                backgroundImage: 'linear-gradient(to right, rgba(63, 81, 181, 0.8) 0%, rgba(0, 0, 0, 1) 100%)',
                backgroundSize: '100% 100%', // adjust the size to your liking
                backgroundPosition: '0% 0%',
                backgroundRepeat: 'no-repeat',
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection:"row"
            }}>
                {childrenActions}
                <Button variant='contained' sx={{ margin: 2, borderRadius: 10, padding: 2, minWidth: 150 }} onClick={onSave} >Save</Button>
            </Stack>
        </BottomNavigation>
    )
}

export { BottomSaveBar };