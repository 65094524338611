import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {current} from "immer"
import { BillItem, BillType } from '../../../all-types'
import { defaultBillType } from './default-bill-data'
import { addDataToOfflineDB, OfflineStores, updateDataToOfflineDB } from '../../../offline-db'
import { createNewBill } from '../../../services'

export type OfflineBill = {
  sessionId: string;
  bill: BillType
  isUploadedOnline: boolean
  isActive: boolean
  createdTime: string
}

export interface BillingState {
  offlineBills: { [billId: string]: OfflineBill }
  activeBills: { [uniqueTableNo: string]: OfflineBill }
}

const initialState: BillingState = {
  offlineBills: {},
  activeBills: {},
}

const newBillSession: OfflineBill = {
  sessionId: "",
  bill: defaultBillType,
  createdTime: '',
  isUploadedOnline: false,
  isActive: true,
}

export const BillingStateSlice = createSlice({
  name: 'billState',
  initialState,
  reducers: {
    loadActiveBills:(state, action: PayloadAction<{ [uniqueTableNo: string]: OfflineBill }>) => {
      state.activeBills = action.payload;
    },
    createBillSession: (state, action: PayloadAction<string>) => {
      const bSession = {...newBillSession, sessionId: action.payload};
      state.activeBills[action.payload] = bSession;
      addDataToOfflineDB(OfflineStores.ActiveBills, bSession).then((res) => {
        console.log('Bill session created', res);
      });
    },
    removeBillSession: (state, action: PayloadAction<string>) => {
      if(state.activeBills[action.payload]) {
        delete state.activeBills[action.payload];
      }
    },
    updateBill: (state, action: PayloadAction<{id: string, bill: BillType}>) => {
      const {id, bill} = action.payload;
      if(state.activeBills[id]) {
        state.activeBills[id].bill = bill;
        updateDataToOfflineDB(OfflineStores.ActiveBills, id,  current(state.activeBills[id]));
      }
    },
    addItemsTotheBill: (state, action: PayloadAction<{id:string, billItem: BillItem, editIndex: number | null}>) => {
      const {id, billItem, editIndex} = action.payload;
      const activeBill = state.activeBills[action.payload.id];
        if(activeBill) {
          if(editIndex !== null) {
            activeBill.bill.items[editIndex-1] = {...billItem, sno: editIndex-1};
          } else {
            const itemsCount = activeBill.bill.items?.length;
            activeBill.bill.items.push({...billItem, sno: itemsCount});
          }
          updateDataToOfflineDB(OfflineStores.ActiveBills, id,  current(activeBill));
        }
    },
    deleteTtemIntheBill: (state, action: PayloadAction<{id:string, index: number }>) => {
      const {id, index} = action.payload;
      const activeBill = state.activeBills[action.payload.id];
        if(activeBill) {
          activeBill.bill.items.splice(index, 1);
          updateDataToOfflineDB(OfflineStores.ActiveBills, id,  current(activeBill));
        }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewBill.fulfilled, (state, action) => {
        //Remove Bill
        console.log("Reomve in ofline DB ")
      })
      .addCase(createNewBill.rejected, (state, action) => {
        //SAVE IN DB
        console.log("add in ofline DB ")
      });
  },
})

export const {
  loadActiveBills,
  createBillSession,
  removeBillSession,
  updateBill,
  addItemsTotheBill,
  deleteTtemIntheBill,
} = BillingStateSlice.actions
