import { Stack } from "@mui/material"
import { RestaurantTable } from "../../../components"
import { useAppSelector } from "../../../redux-store/hooks"


const TablesViewer = () => {
    const tables = useAppSelector(state => state.clientInfo.data.tableManager.tables);

    return (
        <Stack flex={1} flexDirection={"row"} sx={{flexWrap: "wrap", alignItems:"center", justifyContent:"center"}} gap={2}>
            {(tables && Object.values(tables).length > 0) && Object.values(tables)?.map(t => (
                <Stack key={t.tableId}>
                    <RestaurantTable table={t} />
                </Stack>
            ))}
        </Stack>
    )
}

export { TablesViewer }