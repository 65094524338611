import { BillType } from '../../../all-types'

export const defaultBillType: BillType = {
  billNo: '',
  sNo: 0,
  clientId: '',
  items: [],
  billingInfo: {
    totalItemsCostRaw: 0,
    totalDiscountIssued: 0,
    totalTaxCharged: 0,
    TaxChargeSheetView: {},
    finalBillAmount: 0,
  },
  timeInfo: {
    billDate: '',
    billTime: '',
    timestamp: '',
    timezone: '',
    year: 0,
  },
  modeOfPayment: '',
}
