import { Autocomplete, Checkbox, Chip, FormControlLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { DiscountComponent, DiscountComponents, IProductDiscountSetting } from '../../../all-types';
import { useAppSelector } from '../../../redux-store/hooks';
import { getStortType } from '../../../utils';

interface IProductDiscounts {
    data: IProductDiscountSetting;
    onChange: (data: IProductDiscountSetting) => void;
}

const ProductDiscount: FC<IProductDiscounts> = ({
    data, onChange
}) => {
    const discountComponents: DiscountComponents = useAppSelector(state => state.clientInfo.data?.discountManager?.activeDiscounts);
    const [tempData, setTempData] = useState(data);

    const getApplicableDiscountValue = (): DiscountComponent[] => {
        let val: DiscountComponent[] = [];
        try {
            const { appliedComponent } = tempData;
            if (appliedComponent && appliedComponent.length > 0) {
                const allDiscountCompList = Object.values(discountComponents);
                if (allDiscountCompList && allDiscountCompList.length > 0) {
                    appliedComponent.forEach(c => {
                        const discountComp = allDiscountCompList.find(t => t.discountId === c);
                        if (discountComp) {
                            val.push(discountComp);
                        }
                    });
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            return val;
        }
    }

    const [appliedComps, setAppliedComps] = useState(getApplicableDiscountValue());

    const updateApplicableTax = (newValue: DiscountComponent[]) => {
        try {
            const tempComps = [...newValue];
            setAppliedComps(tempComps);
            const temp = {
                ...tempData,
                appliedComponent: newValue.map(option => option.discountId)
            }
            setTempData(temp);
            updateDataToParent(temp);
        }
        catch (e) {
            console.error(e);
        }
    }

    const updateDataToParent = (newData: IProductDiscountSetting) => {
        if (onChange) {
            onChange(newData);
        }
    }

    return (
        <Stack>
            {/* <FormControlLabel
                control={
                    <Checkbox
                        checked={tempData.enableStrictRules}
                        onChange={(e) => {
                            const temp = { ...tempData, enableStrictRules: e.target.checked };
                            setTempData(temp);
                            updateDataToParent(temp);
                        }}
                        name="enableStrictRules"
                    />
                }
                label="Enable Strict Rules"
            /> */}

            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={tempData.enableStrictRules}
                name="radio-buttons-group"
                onChange={(e) => {
                    const temp = { ...tempData, enableStrictRules: e.target.value === 'true' };
                    setTempData(temp);
                    updateDataToParent(temp);
                }}
            >
                <FormControlLabel value="false" control={<Radio />} label="Default Parent Discounts" />
                <FormControlLabel value="true" control={<Radio />} label="Enforce Product Discount" />
            </RadioGroup>

            {tempData.enableStrictRules && (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tempData.isDiscountApplicable}
                                onChange={(e) => {
                                    const temp = { ...tempData, isDiscountApplicable: e.target.checked };
                                    setTempData(temp);
                                    updateDataToParent(temp);
                                }}
                                name="isDiscountApplicable"
                            />
                        }
                        label="Is Discount Applicable"
                    />
                    <Stack ml={5}>

                    <FormControlLabel
                            control={
                                <Checkbox
                                    checked={tempData.allowTotalBillDiscount}
                                    onChange={(e) => {
                                        const temp = { ...tempData, allowTotalBillDiscount: e.target.checked };
                                        setTempData(temp);
                                        updateDataToParent(temp);
                                    }}
                                    name="allowTotalBillDiscount"
                                />
                            }
                            disabled={!tempData.isDiscountApplicable}
                            label="Allow Total Bill Discount"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={tempData.enableProductDiscount}
                                    onChange={(e) => {
                                        const temp = { ...tempData, enableProductDiscount: e.target.checked };
                                        setTempData(temp);
                                        updateDataToParent(temp);
                                    }}
                                    name="enableProductDiscount"
                                />
                            }
                            disabled={!tempData.isDiscountApplicable}
                            label="Enable Product Discount"
                        />


                    </Stack>


                    <Stack>
                        <Autocomplete
                            multiple
                            id="fixed-tags-demo"
                            value={appliedComps}
                            onChange={(event, newValue) => {
                                updateApplicableTax(newValue);
                            }}
                            options={Object.values(discountComponents)}
                            getOptionLabel={(option) => option.discountName}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    return (
                                        <Chip
                                            key={key}
                                            label={<span>
                                                {option.discountName}
                                                <small>({option.applicableDiscount} {getStortType(option.discountType)})</small>
                                            </span>}
                                            {...tagProps}
                                        />
                                    );
                                })
                            }
                            style={{ width: 500 }}
                            disabled={!tempData.isDiscountApplicable || !tempData.enableProductDiscount}
                            renderInput={(params) => (
                                <TextField {...params} label="Applicable Discount" placeholder="Discount Components" />
                            )}
                        />
                    </Stack>

                </>
            )}
        </Stack>
    );
};

export { ProductDiscount };
