import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const ModalBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'white', //TODO
  border: '2px solid #000', //TODO
  padding: '16px',
})
