import * as Mui from '@mui/material';
import React from 'react';
import { LW_POS_WHITE } from '../../constants';
import { useStartupLoader } from '../../hooks';

const StartupLoader: React.FC = () => {
    const theme = Mui.useTheme();//TODO
    const { message } = useStartupLoader();

    return (
        <Mui.Stack height={"100vh"} gap={2} justifyContent={"center"}
            alignItems={"center"}
            sx={{//TODO
                backgroundImage: 'linear-gradient(to right, rgba(63, 81, 181, 0.8) 0%, rgba(0, 0, 0, 1) 100%)',
                backgroundSize: '100% 100%', // adjust the size to your liking
                backgroundPosition: '0% 0%',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <img
                src={LW_POS_WHITE}
                alt="Product"
                style={{ maxWidth: 500, objectFit: "fill", borderRadius: 30 }}
            />
            <Mui.Box sx={{ width: 200 }}>
                <Mui.LinearProgress />
                <Mui.Typography variant='body1' sx={{ color: "white" }}>{message}</Mui.Typography>
            </Mui.Box>
        </Mui.Stack>
    );
};

export { StartupLoader };

