import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import i18n from "i18next";
import { useTranslation, initReactI18next, I18nextProvider  } from "react-i18next";

import App from "./App";
import { store } from "./redux-store/store";
import "./index.css";
import en from "./lang/en/translation.json";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

i18n.use(initReactI18next).init({
  interpolation: {escapeValue: false},
  lng: "en",
  fallbackLng: "en",
  resources: {
    en: {
      global: en
    }
  }
})

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      <App />
    </I18nextProvider>
  </Provider>
);
