import React, { useEffect, useState } from 'react';
import { Stack, Box, Button, Typography, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { DataGrid } from '@mui/x-data-grid';
import { CommonBtn } from '../../../components/button/common-btn';
import { deleteTtemIntheBill } from '../../../redux-store';
import { BillItem } from '../../../all-types';

interface CartPanelProps {
    currentTab: string;
    handleItemEdit: (productId: string, editIndex: number) => void;
}

const CartPanelItems: React.FC<CartPanelProps> = ({ currentTab, handleItemEdit }) => {
    const cartItems = useAppSelector(state => state.billing.activeBills[currentTab].bill.items);
    const dispatcher = useAppDispatch();
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);

    // Calculate the total number of pages
    const totalPages = Math.ceil(cartItems.length / pageSize);

    const handleEdit = () => {
        try {
            if (selectedProductId && selectedRow) {
                handleItemEdit(selectedProductId, selectedRow);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setSelectedRow(null);
        }
    };

    const handleDelete = () => {
        try {
            if (selectedRow) {
                dispatcher(deleteTtemIntheBill({
                    id: currentTab,
                    index: (selectedRow - 1)
                }))
            }
        } catch (e) {
            console.log(e);
        } finally {
            setSelectedRow(null);
        }
    };

    // Handle pagination
    const handleNextPage = () => {
        if (page < totalPages - 1) {
            setPage(page + 1);
            setSelectedRow(null);
        }
    };

    const handlePrevPage = () => {
        if (page > 0) {
            setPage(page - 1);
            setSelectedRow(null);
        }
    };

    useEffect(() => {
        setPage(totalPages - 1);
        setSelectedRow(null);
    }, [cartItems.length])

    const columns = [
        {
            field: 'id', headerName: 'Cart', flex: 0.2, components: {
                Header: () => {
                    return null;
                },
            },
            renderCell: (params: any) => {
                const product: BillItem = params.row?.product;
                return (
                    <Stack flexDirection={"row"} flex={1} gap={2} height={"100%"}>
                        <Stack
                            flex={2} justifyContent={"center"}>
                            <Stack
                                height={50}
                                sx={{
                                    backgroundImage: `url(${product?.itemImage})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    borderRadius: 2
                                }}>
                            </Stack>
                        </Stack>
                        <Stack flex={8} justifyContent={"center"}>
                            <Stack>
                                <Typography variant='body1'>{product.itemName}</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Stack>
                                    <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                        <Typography variant='body2'>{`${product.quantity} ${product.quantityType}  `}</Typography>
                                        <Typography variant='body2'>{` x ${product.noOfItems}`} </Typography>
                                    </Stack>
                                    <Stack>
                                    </Stack>
                                </Stack>
                                <Stack >
                                    <Typography variant='h6' fontWeight={"bold"}>{product.amount}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                )
            },
        },
        // { field: 'itemName', headerName: 'Item', flex: 4 },
        // { field: 'quantity', headerName: 'Qty', flex: 1.5 },
        // { field: 'amount', headerName: 'Amount', flex: 1.5 },
    ];

    const rows = cartItems
        ?.slice(page * pageSize, page * pageSize + pageSize) // Paginate the rows
        .map((item, index) => ({
            id: page * pageSize + index + 1, // Adjust index based on pagination
            itemName: item.itemName,
            quantity: item.quantity,
            amount: item.amount,
            productId: item.productId,
            product: item
        }));

    const REDUCE = 40;
    const ROW_HEIGHT = 100 - REDUCE;

    return (
        <Stack flex={1} sx={{ position: 'relative', width: "100%" }}>
            {/* Custom Pagination Controls */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, p: 1 }}>
                <Button
                    onClick={handlePrevPage}
                    disabled={page === 0}
                    variant="contained"
                    sx={{width: 50, height:50}}
                    startIcon={<KeyboardArrowLeftIcon fontSize="large" sx={{color: "white", marginLeft: 1}} />
                    }
                />

                <Typography sx={{ alignSelf: 'center' }}>
                    {page + 1} of {totalPages}
                </Typography>
                <Button
                    onClick={handleNextPage}
                    disabled={page >= totalPages - 1}
                    variant="contained"
                    sx={{width: 50, height:50}}
                    startIcon={<KeyboardArrowRightIcon  fontSize="large"  sx={{color: "white", marginLeft: 1}} />
                    }
                />
                {/* <CommonBtn
                    onClick={handleNextPage}
                    disabled={page >= totalPages - 1}
                    variant="contained"
                >
                    Next
                </CommonBtn> */}
            </Box>

            <DataGrid
                disableColumnResize
                rows={rows}
                columns={columns}
                rowHeight={ROW_HEIGHT}
                disableColumnSorting
                disableColumnFilter
                disableColumnMenu
                pageSizeOptions={[pageSize]} // Disable internal pagination
                hideFooterPagination // Hide the default pagination
                onRowClick={(params) => {
                    setSelectedRow(params.row.id);
                    setSelectedProductId(params.row.productId);
                }}
                sx={{
                    maxWidth: '100%',
                    '& .MuiDataGrid-footerContainer': {
                        display: 'none', // Hides pagination footer and removes its space
                    },
                    '& .MuiDataGrid-columnHeader': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-scrollbar': {
                        display: 'none',
                    },
                }}
            />

            {selectedRow !== null && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: `${(selectedRow > 5 ? 
                            (selectedRow - (5 * (page))) * ROW_HEIGHT : 
                            (selectedRow * ROW_HEIGHT)) - (34 - REDUCE)}px`,
                        left: 0,
                        width: '100%',
                        height: `${ROW_HEIGHT}px`,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 1,
                        zIndex: 1,
                    }}
                >
                    <CommonBtn variant='contained' startIcon={<EditIcon />}
                        color='info' onClick={handleEdit}>Edit</CommonBtn>
                    <CommonBtn variant='contained' startIcon={<DeleteIcon />} color='error'
                        onClick={handleDelete}>Delete</CommonBtn>
                </Box>
            )}
        </Stack>
    );
};

export { CartPanelItems };
