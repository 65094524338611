export enum DISCOUNT_TYPE {
  PERCENTAGE = "PERCENTAGE",
  CASH = "CASH",
}

export interface DiscountComponent {
  discountId: string;
  discountName: string;
  applicableDiscount: number;
  discountType: DISCOUNT_TYPE;
  isActive: boolean;
  startDate: string;
  endDate: string;
}

export type DiscountComponents = {[key: string ] : DiscountComponent};

export interface IClientDiscountManager {
  isDiscountsApplicable: boolean;
  allowDiscountOnTotalBill: boolean;
  allowDefaultDiscount: boolean;
  considerStrictProductSettings: boolean;
  activeDiscounts: DiscountComponents;
  deactiveDiscounts: DiscountComponents;
}
