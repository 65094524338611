import { AppConfig } from "../config";


const DOMAIN = AppConfig.getBaseURL();
const EMAIL_DOMAIN = AppConfig.getEmailBaseUrl();
const EMAIL_API_KEY = AppConfig.getEmailApiKey();
const V1_ADMIN_PATH = "/v1/admin/private/api"
const URLS = {
    BASE_URL: DOMAIN,
    EMAIL_BASE_URL: EMAIL_DOMAIN,
    LOGIN : `${V1_ADMIN_PATH}/login`,
    GET_CLIENT_INFO : `${V1_ADMIN_PATH}/get-client-info`,
    UPDATE_CLIENT_INFO: `${V1_ADMIN_PATH}/update-client-info`,
    UPDATE_PAYTM_CR_SETTINGS: `${V1_ADMIN_PATH}/update/paytm/cr/settings`,
    UPDATE_PRINTER_SETTINGS: `${V1_ADMIN_PATH}/update-printer-settings`,
    GET_PRODUCTS_LIST: `${V1_ADMIN_PATH}/get-product-list`,
    DELETE_PORODUCT:`${V1_ADMIN_PATH}/delete-product`,
    CREATE_PRODUCT: `${V1_ADMIN_PATH}/create-product`,
    UPADTE_PRODUCT: `${V1_ADMIN_PATH}/update-product`,
    ADD_CATEGORY: `${V1_ADMIN_PATH}/create-category`, // 
    GET_CATEGORY: `${V1_ADMIN_PATH}/get-category-list`,
    FILE_UPLOAD_URL: `${V1_ADMIN_PATH}/uploadFile`,
    CREATE_BILL:`${V1_ADMIN_PATH}/create-bill`
};

export {URLS,EMAIL_API_KEY}