import { createAsyncThunk } from '@reduxjs/toolkit'
import { BillType } from '../all-types'
import { axiosClient } from '../api'
import { URLS } from '../constants'
import { getCurrentTimeInfo, isResponseSuccess } from '../utils'
import { AppConfig } from '../config'

export const createNewBill = createAsyncThunk(
  'bill/createNewBill',
  async (details: { bill: BillType }, { dispatch, rejectWithValue }) => {
    try {
      const timeInfo = getCurrentTimeInfo()
      const uniqueBillNo = String(timeInfo.year).concat(
        String(timeInfo.timestamp)
      ) //TODO

      details.bill = {
        ...details.bill,
        clientId: AppConfig.getCLientId() || '',
        billNo: uniqueBillNo,
        timeInfo: {
          billDate: timeInfo.date,
          billTime: timeInfo.time,
          timestamp: String(timeInfo.timestamp),
          timezone: timeInfo.timezone || '',
          year: timeInfo.year,
        },
      }

      const response = await axiosClient.post(`${URLS.CREATE_BILL}`, {
        bill: details.bill,
      })
      if (!isResponseSuccess(response.status)) {
        throw new Error('Failed to save printer configuration.')
      }
      return response.data.data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)
