import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState= {
    STANDARD_HEADER_HEIGHT: 64,
    HEADER_HEIGHT: 64,
    
};

export const UISlice = createSlice({
  name: "quantityTypes",
  initialState,
  reducers: {
    setHeaderHeight: (state, action: PayloadAction<number>) => {
      state.HEADER_HEIGHT = action.payload;
    },
  },
});

export const { setHeaderHeight } = UISlice.actions;
