import { Stack, Typography, SvgIcon, CircularProgress } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';
import StorageIcon from '@mui/icons-material/Storage';
import { useNetworkStatus, useOfflineDb } from "../../hooks";

const StatusIndicator = () => {
  const { isDbReady } = useOfflineDb();
  const { isOnline } = useNetworkStatus();
  return (
    <Stack direction="row" alignItems="center"  justifyContent="center" spacing={1}>

      <Stack direction="row" alignItems="center" spacing={1}>
        {isOnline ? (
          <SignalWifi3BarIcon color="success" />
        ) : (
          <SignalWifi3BarIcon color="error" />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1}>
        {isDbReady ? (
          <StorageIcon color="success" />
        ) : (
          <StorageIcon color="error" />
        )}
      </Stack>

      {/* <Stack direction="row" alignItems="center" spacing={1}>
        {isDbReady ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CircularProgress size={24} />
        )}
        <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
          {isDbReady ? "Offline Support" : "Initializing database..."}
        </Typography>
      </Stack> */}

    </Stack>
  );
};

export { StatusIndicator };