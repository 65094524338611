import { Checkbox, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { updateClientInfo } from '../../../services';
import { BottomSaveBar, ClientTaxList } from '../components';


const ClientTaxSettings = () => {
    const clientInfo = useAppSelector(state => state.clientInfo.data);
    const dispatcher = useAppDispatch();
    const [tmanager, setTmanager] = useState(clientInfo.taxManager);

    const updateTaxSettings = async () => {
        try {
            const tempClient = { ...clientInfo };
            tempClient.taxManager = {
                ...tempClient.taxManager,
                isTaxApplicable: tmanager.isTaxApplicable,
                applyDefaultTax: tmanager.applyDefaultTax,
                considerStrictProductSettings: tmanager.considerStrictProductSettings
            };
            await dispatcher(updateClientInfo({
                clientId: tempClient._id,
                body: tempClient
            }));
        } catch (e) {
            console.error(e);
        }
    }


    return (
        <Stack p={2}>

            <FormGroup>
                <FormLabel component="legend">Global Tax Options</FormLabel>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={tmanager.isTaxApplicable}
                            onChange={(e) => setTmanager({ ...tmanager, isTaxApplicable: e.target.checked })}
                        />
                    }
                    label="Is Tax Applicable"
                />
                <Stack ml={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tmanager.applyDefaultTax}
                                onChange={(e) => setTmanager({ ...tmanager, applyDefaultTax: e.target.checked })}
                            />
                        }
                        disabled={!tmanager.isTaxApplicable}

                        label="Apply Default Tax"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tmanager.considerStrictProductSettings}
                                onChange={(e) => setTmanager({ ...tmanager, considerStrictProductSettings: e.target.checked })}
                            />
                        }
                        disabled={!tmanager.isTaxApplicable}
                        label={
                            <span>
                                Enforce Strict Product Tax
                                <br />
                                <small>Overrides global tax with product-specific settings</small>
                            </span>
                        }
                    />

                </Stack>
            </FormGroup>

            <ClientTaxList />
            <BottomSaveBar onSave={updateTaxSettings} />
        </Stack>
    );
};

export { ClientTaxSettings };

