// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit'
import {
  BillingStateSlice,
  CategoriesSlice,
  ClientInfoSlice,
  ProductsSlice,
  QuantityTypeSlice,
  UISlice,
  authSlice,
} from './reducers'

export const store = configureStore({
  reducer: {
    //NEW
    clientInfo: ClientInfoSlice.reducer,
    authentication: authSlice.reducer,
    allproducts: ProductsSlice.reducer,
    categories: CategoriesSlice.reducer,
    quantityTypes: QuantityTypeSlice.reducer,
    billing: BillingStateSlice.reducer,
    ui: UISlice.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
