import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Button, FormControlLabel, IconButton, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';

import { IProductRate, QUANTITY_TYPES, RateCard } from '../../../all-types';
import { useDefaultPrice } from '../../../hooks';
import { useAppSelector } from '../../../redux-store/hooks';

interface CProductRatesProps {
    allRate: IProductRate;
    allDefaults?: IProductRate;
    setAllRate: (rate: IProductRate, defaults: IProductRate) => void;
}

const defaultRate = {
    name: "",
    quantity: 1,
    price: 0,
    quantityType: QUANTITY_TYPES.GRAMS,
    default: false
}


const ProductRates: React.FC<CProductRatesProps> = ({ allRate, allDefaults, setAllRate }) => {
    const theme = useTheme();
    const { getDefaultPrice } = useDefaultPrice();
    const quantityTypes = useAppSelector(state => state.quantityTypes.types);
    // const allDef: IProductRate = typeof allDefaults === "object" ? new Map(Object.entries(allDefaults)) : new Map(allDefaults);
    // const allratesByType: IProductRate = typeof allRate === "object" ? new Map(Object.entries(allRate)) : new Map(allRate);

    const [defaults, setDefaults] = useState<IProductRate>(new Map(allDefaults) || new Map());
    const [rates, setRates] = useState<IProductRate>(new Map(allRate));
    const [autoDefault, setAutoDefaults] = useState(true);

    const [newRate, setNewRate] = useState<RateCard>(defaultRate);

    // Handler to add a new rate to the defaults map
    const addRate = () => {
        const rateCardName = String(newRate.quantity) + " " + String(newRate.quantityType);
        newRate.name = rateCardName;
        const r = new Map(rates);
        r.set(rateCardName, newRate);
        setRates(r);
        const defaultPrice = getDefaultPrice({ basePrice: newRate.price, quantity: newRate.quantity });
        const d = new Map(defaults);
        d.set(newRate.quantityType, {
            name: rateCardName,
            quantity: 1,
            price: autoDefault ? defaultPrice : 0,
            default: true,
            quantityType: newRate.quantityType
        });
        setDefaults(d);
        setNewRate(defaultRate); // Reset newRate state
        setAllRate(r, d);
    };

    // Handler to delete a rate from the defaults map
    const deleteRate = (key: string) => {
        const updatedDefaults = new Map(rates);
        const d = new Map(defaults);
        const deletedType = updatedDefaults.get(key)?.quantityType;
        updatedDefaults.delete(key);
        if (deletedType) {
            let hasAnyOtherRecordOfDeleteType = false;
            let leftOverType: any = null;
            updatedDefaults.forEach(entry => {
                if (entry.quantityType === deletedType) {
                    hasAnyOtherRecordOfDeleteType = true;
                    leftOverType = entry;
                }
            });
            if (!hasAnyOtherRecordOfDeleteType) {
                d.delete(deletedType);
            } else {
                if (autoDefault) {
                    const record = d.get(deletedType);
                    if (record && leftOverType !== null) {
                        record.price = getDefaultPrice({ basePrice: leftOverType?.price, quantity: leftOverType?.quantity })
                        d.set(record?.quantityType, record);
                    }
                }
            }
            setDefaults(d);
        }

        setRates(updatedDefaults);
        setAllRate(updatedDefaults, d);
    };

    // Handler to update new rate input fields
    const handleNewRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewRate(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeDefaultPrice = (type: QUANTITY_TYPES, price: number) => {
        const d = new Map(defaults);
        const record = d.get(type);
        if (record) {
            record.price = price;
        }
        setDefaults(d);
    }

    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAutoDefaults(event.target.checked);
    };

    return (
        <Stack>
            <FormControlLabel
                control={
                    <Switch
                        checked={autoDefault}
                        onChange={handleToggle}
                        inputProps={{ 'aria-label': 'auto default toggle' }}
                    />
                }
                label="Auto Default"
                style={{ width: "40%" }}
            />
            <TableContainer>
                <Table>
                    <TableHead style={{ backgroundColor: theme.palette.background.default }}>
                        <TableRow>
                            <TableCell sx={{ color: theme.palette.CommonWhite.dark }} color={theme.palette.CommonWhite.dark}>Quantity</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Price</TableCell>
                            {/* <TableCell>default</TableCell> */}
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...rates?.entries()].map(([key, rate]) => (
                            <TableRow key={rate.name}>
                                <TableCell >{rate.quantity}</TableCell>
                                <TableCell>{rate.quantityType}</TableCell>
                                <TableCell>{rate.price}</TableCell>
                                {/* <TableCell>{rate.default}</TableCell> */}

                                <TableCell>
                                    <IconButton color='error' onClick={() => deleteRate(rate.name)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}

                        <TableRow key="all-actions">

                            <TableCell size='small' style={{ width: '20%' }}><TextField
                                label="Quantity"
                                name="quantity"
                                type="number"
                                value={newRate.quantity}
                                onChange={handleNewRateChange}
                                required
                            /></TableCell>
                            <TableCell >
                                <Autocomplete
                                    fullWidth
                                    options={quantityTypes}
                                    getOptionLabel={(option) => option} // Display the quantity type as the label
                                    value={newRate.quantityType}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setNewRate({ ...newRate, quantityType: newValue });
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Type"
                                            required
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) => option === value}
                                />
                            </TableCell>
                            <TableCell size='small' style={{ width: '25%' }}>
                                <TextField
                                    label="Price"
                                    name="price"
                                    type="number"
                                    value={newRate.price}
                                    onChange={handleNewRateChange}
                                    required
                                /></TableCell>
                            {/* <TableCell size='small' style={{ width: '5%' }}>
                            <Checkbox value={newRate.default} defaultChecked />
                            </TableCell> */}
                            <TableCell size='small' style={{ width: '5%' }}>
                                <Button color='primary' onClick={addRate}>Add</Button>
                                {/* <IconButton color='primary' onClick={addRate}>
                                    <AddIcon />
                                </IconButton> */}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>


            <Typography mt={5}>Defaults</Typography>
            <TableContainer>
                <Table>
                    <TableHead style={{ backgroundColor: theme.palette.secondary.light }}>
                        <TableRow>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Price</TableCell>
                            {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...defaults?.entries()].map(([key, rate]) => (
                            <TableRow key={rate.name}>
                                <TableCell size='small' style={{ width: '20%' }}><TextField
                                    label="Quantity"
                                    name="quantity"
                                    type="number"
                                    value={rate.quantity}
                                    //onChange={handleNewRateChange}
                                    required
                                    disabled
                                /></TableCell>
                                <TableCell>{rate.quantityType}</TableCell>
                                <TableCell size='small' style={{ width: '25%' }}>
                                    <TextField
                                        label="Price"
                                        name="price"
                                        type="number"
                                        value={rate.price || ""}
                                        onChange={(e) => handleChangeDefaultPrice(rate.quantityType, Number(e.target.value))}
                                        required
                                    /></TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};

export { ProductRates };

