import React, { useState } from 'react'
import { initStripo } from './stripo.helpers'
import { Grid, Button, Box, Dialog, DialogContent, DialogTitle, MenuItem, Select, TextField } from '@mui/material'
import { IRequestTemplate } from '../../all-types'
import { axiosEmailClient } from '../../api'

interface StripoProps {
  html?: string
  css?: string
  pluginId: string
  secretKey: string
}

export default function Stripo(props: StripoProps) {
  const { html, css, pluginId, secretKey } = props
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [warningDialog, setWarningDialog] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string>("");
  React.useEffect(() => {
    if (html && css) {
      initStripo({ html, css, pluginId, secretKey })
      const element = document.getElementById('codeEditor')
      if(element) {
        element.setAttribute("close", "false");  
      }

      
    }

    // const docs = document.getElementsByClassName('sripo-watermark')
    // docs.forEach(element => {
    //   element.innerHTML =''
    // });

  }, [html, css, pluginId, secretKey])

  const handleCode = () => {

    const element = document.getElementById('codeEditor');

    setTimeout(() => {
        //@ts-ignore
        let editor = ace?.edit('esdev-html-code-editor-panel')
        var code = editor.getValue()
        console.log(code)
        if(!code){
          element?.click() 
          return
        }
        setTemplateForm({
            templateName: "",
            template: "",
            type: "static",
          });
        setTemplateForm((prev)=>(
          {
            ...prev,
          template:code
        }))
        setDialogOpen(true)
    }, 100);
    
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTemplateForm({
      ...templateForm,
      [name]: value,
    });
  };

  const [templateForm, setTemplateForm] = useState<Partial<IRequestTemplate>>({
    templateName: "",
    template: "",
    type: "static",
  });


  const validateForm=()=>{
    let isValid = true;
    let msg=''
    if(!templateForm.template?.trim()){
      msg+='Template is required \n '
      isValid=false
    }
    if(!templateForm.templateName?.trim()){
      msg+=' Name  is required \n '
      isValid=false
    }
    if(!templateForm.type?.trim()){
      msg+=' Type is required \n '
      isValid=false
    }

      if(!isValid){
        alert(msg)
      }

      return isValid
  }

  const onSave = async () => {

    if(!validateForm()) return

    try {
      if(currentId ){
        templateForm._id=currentId;
      }
      const req = await axiosEmailClient.post(
        "/masters/template",
        templateForm
      );

      setTemplateForm({
        templateName: "",
        template: "",
      });
      setDialogOpen(false);

    } catch (err) {
      console.log(err);
    }
  };


  return (
    <>
      <Grid item xs={4} alignSelf={'center'} justifyContent={'flex-end'} marginBottom={'10px'} >
        <Button
          variant="contained"
          id="codeEditor"
          onClick={() => handleCode()}
        >
          Save Template
        </Button>
      </Grid>
      <div className="stripo">
        <div className="stripo__content">
          <div id="stripoSettingsContainer">Loading...</div>
          <div id="stripoPreviewContainer"></div>
        </div>
      </div>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Templates</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              padding: "30px",
              border: "1px solid black",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "50px" }}
                  variant="outlined"
                  value={templateForm.templateName}
                  onChange={handleChange}
                  fullWidth
                  name="templateName"
                  label="Name"
                  placeholder="Enter Template Name"
                ></TextField>
              </Grid>
              <Grid item xs={4} alignSelf={"center"}>
                <TextField
                  sx={{ borderRadius: "6px" }}
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  name="template"
                  label="Template"
                  value={templateForm.template}
                  required
                  placeholder="Enter Template"
                ></TextField>
              </Grid>
              <Grid item xs={4} alignSelf={"center"}>
                <Select
                  sx={{ borderRadius: "6px" }}
                  variant="outlined"
                  onChange={handleChange as any}
                  fullWidth
                  name="type"
                  value={templateForm.type}
                  required
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <em>Select Template Type</em>
                  </MenuItem>
                  <MenuItem value="static">Static</MenuItem>
                  <MenuItem value="dynamic">Dynamic</MenuItem>
                </Select>
              </Grid>

              <Grid
                item
                xs={4}
                alignSelf={"center"}
                justifyContent={"flex-end"}
              >
                <Button variant="contained" onClick={() => onSave()}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
