import { Box, Button, CardActionArea, Skeleton, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { ProductType } from "../../../all-types";
import { useEffect, useState } from "react";
import { NO_IMAGE_URL } from "../../../constants";

export interface ItemCardProps {
    handleOpen: (item: ProductType) => void;
    item: ProductType;
}

export default function ProductCard({ handleOpen, item }: ItemCardProps) {
    const theme = useTheme();
    const [isImageLoaded, setImageLoaded] = useState(false);
    const [imageSrc, setImageSrc] = useState(item.image);

    const isNotAvailableStyle = {
        filter: "grayscale(100%)",
        pointerEvents: "none",
        backgroundColor: "#f0f0f0",
    };

    useEffect(() => {
        const img = new Image();
        img.src = item.image;

        const handleLoad = () => setImageLoaded(true);
        const handleError = () => {
            setImageSrc(NO_IMAGE_URL);
            setImageLoaded(true);
        };

        img.addEventListener("load", handleLoad);
        img.addEventListener("error", handleError);

        return () => {
            img.removeEventListener("load", handleLoad);
            img.removeEventListener("error", handleError);
        };
    }, [item.image]);

    return (
        <Card
            onClick={() => {
                handleOpen(item);
            }}
            sx={{
                ...(item?.isInStock ? {} : isNotAvailableStyle),
                display: "flex",
                flexDirection: "row",
                boxShadow: "0px 2px 4px rgba(0, 0, 1, 0.2)",
                width: { xs: "90%", sm: "90%", md: "90%" },
                height: { xs: "auto", sm: "12.8125rem", md: "13.75rem" },
                borderRadius: "10px",
            }}
        >
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
                {isImageLoaded ? (
                    <>
                        <CardMedia
                            component="img"
                            sx={{ width: "100%", height: "60%", borderRadius: "10px" }}
                            image={imageSrc}
                            alt={item.name}
                            loading="lazy"
                        />
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "100%",
                                paddingRight: "0px",
                                width: "100%",
                                "&:last-child": { paddingBottom: "8px" },
                                padding: "1rem"
                            }}
                        >
                            <Box>
                                <Typography
                                    gutterBottom
                                    component="div"
                                    sx={{ fontWeight: "bold", fontSize: "1rem", lineHeight: 1.5 }}
                                >
                                    {item.name}
                                </Typography>
                            </Box>
                            {/* <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    borderRadius: "8px",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "0.4rem 0.75rem",
                                    textTransform: "none",
                                }}
                            >
                                Add +
                            </Button> */}
                        </CardContent>
                    </>
                ) : (
                    <Skeleton
                        variant="rectangular"
                        sx={{
                            backgroundColor: theme.palette.CommonWhite.light,
                        }}
                        animation="wave"
                        width={"100%"}
                        height={"100%"}
                    />
                )}
            </CardActionArea>
        </Card>
    );
}
