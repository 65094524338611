import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { DISCOUNT_TYPE, TAX_TYPE } from "../../../all-types";
import { useAppSelector } from "../../../redux-store/hooks";


interface CartItemsSummaryProps {
    currentTab: string
}

const CartItemsSummary: FC<CartItemsSummaryProps> = ({ currentTab }) => {
    const theme = useTheme();
    const cartItems = useAppSelector(state => state.billing.activeBills[currentTab].bill.items);

    return (
        <Stack>
            {cartItems?.map((item) => (
                <Stack key={item.sno} flexDirection={"row"} height={"auto"} gap={1} margin={1} padding={1}
                    sx={{ boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px`, borderRadius: 3 }}>
                    <Stack
                        flex={2} justifyContent={"flex-start"} alignItems={"center"}>
                        <Stack
                            height={"100%"}
                            width={"100%"}
                            maxWidth={100}
                            maxHeight={100}
                            sx={{
                                backgroundImage: `url(${item?.itemImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: 2
                            }}>
                        </Stack>
                    </Stack>
                    <Stack flex={8} justifyContent={"center"}>
                        <Stack>
                            <Typography variant='body1'>{item.itemName}</Typography>
                        </Stack>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Stack>
                                <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                    <Typography variant='body2'>{`${item.quantity} ${item.quantityType}  `}</Typography>
                                    <Typography variant='body2'>{` x ${item.noOfItems}`} </Typography>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Typography variant='h6' fontWeight={"bold"}>{item.amount}</Typography>
                            </Stack>

                        </Stack>

                        <Stack gap={1}>
                            {item.billingInfo.discounts.isDiscountApplied && (
                                <Stack gap={1}>
                                    {item.billingInfo.discounts.discountsInfo?.map(dInfo => (
                                        <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Stack>
                                                <Chip label={`${dInfo.discountComponent.discountName}`}
                                                    color="success" />
                                            </Stack>

                                            <Stack>
                                                <Typography variant='body2'>{`- ${dInfo.discountAmount} ( ${dInfo.discountComponent.applicableDiscount}${dInfo.discountComponent.discountType === DISCOUNT_TYPE.PERCENTAGE ? '%' : ''})`}</Typography>
                                            </Stack>
                                        </Stack>
                                    ))}
                                </Stack>
                            )}

                            {item.billingInfo.tax.isTaxApplied && (
                                <Stack gap={1}>
                                    {item.billingInfo.tax.taxInfo?.map(tInfo => (
                                        <Stack key={tInfo.taxComponent.taxId} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Stack>
                                                <Chip label={`${tInfo.taxComponent.taxName}`}
                                                    color="error" />
                                            </Stack>

                                            <Stack>
                                                <Typography variant='body2'>{`+ ${tInfo.taxAmount} ( ${tInfo.taxComponent.applicableTax}${tInfo.taxComponent.taxType === TAX_TYPE.PERCENTAGE ? '%' : ''})`}</Typography>
                                            </Stack>
                                        </Stack>
                                    ))}
                                </Stack>
                            )}

                            <Stack alignItems={"flex-end"}>
                                <Box sx={{ backgroundColor: theme.palette.error.light, padding: 1 }}>
                                    <Typography variant='h6' fontWeight={"bold"}>= {item.billingInfo.finalAmount}</Typography>
                                </Box>
                            </Stack>

                        </Stack>
                    </Stack>
                </Stack>
            ))}

        </Stack>
    )
}

export { CartItemsSummary };
