import {
  Box,
  Stack
} from '@mui/material';
import { STYLE_CONSTANTS } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import {
  FloorViewer, PlacesViewer, TablesViewer
} from '../../client-info';
import { RestaurantTable } from '../../../components';
import { FC } from 'react';
import { ITables } from '../../../all-types';




const SCROLL_STYLES = {
  overflowY: 'auto',
  borderRight: `1px solid black`,
  borderLeft: `1px solid black`,
  scrollbarWidth: 'thin',
  position: "relative",
  overflowX: "hidden",
}

export interface ItableAssignerProps {
   onTableSelect: (tableName: ITables) =>  void;
} 

const TableAssigner:FC<ItableAssignerProps> = ({onTableSelect}) => {
  const tables = useAppSelector(state => state.clientInfo.data.tableManager.tables);
  const STANDARD_HEADER_HEIGHT = useAppSelector(state => state.ui.STANDARD_HEADER_HEIGHT);
  const OCCUPIED_HEIGHT = STANDARD_HEADER_HEIGHT+ STYLE_CONSTANTS.SETTING_BOTTOM_BAR_HEIGHT;
  const VIEW_HEIGHT = `calc(100vh - ${OCCUPIED_HEIGHT}px)`

  return (
    <Box display="flex" flexDirection={"column"} flex={1}>
      <Stack flexDirection={"row"} flex={1}>
        <Stack flex={1} height={VIEW_HEIGHT} sx={{
          ...SCROLL_STYLES,
          minWidth: "150px"
        }}>
          <PlacesViewer />
        </Stack>
        <Stack flex={8} height={VIEW_HEIGHT} sx={{ ...SCROLL_STYLES }}>
          <Stack flex={1} flexDirection={"row"} sx={{ flexWrap: "wrap", alignItems: "center", justifyContent: "center" }} gap={2}>
            {(tables && Object.values(tables).length > 0) && Object.values(tables)?.map(t => (
              <Stack key={t.tableId}>
                <RestaurantTable table={t}  onTableSelect={onTableSelect}/>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack flex={1} height={VIEW_HEIGHT} sx={{
          ...SCROLL_STYLES,
          minWidth: "150px"
        }}>
          <FloorViewer />
        </Stack>
      </Stack>
    </Box>
  );
};

export { TableAssigner };

