import CancelIcon from '@mui/icons-material/Cancel';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Button, Chip, Grid, IconButton, Modal, Stack, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import SearchInput from '../../components/Input/SearchInput/SearchInput';
import { useProductSearch } from '../../hooks';
import { BillingScreen } from './billing';
import { TableAssigner } from './modules';
import { TAB_STYLES } from './styles';
import { useTranslation } from 'react-i18next';
import { ITables } from '../../all-types';
import { LW_POS_BLACK } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux-store/hooks';
import { createBillSession } from '../../redux-store';

interface TabData {
    uniqueTableName: string;
    label: string;
    floor: number;
    no: number;
}

const BillingInTabs: React.FC = () => {
    const theme = useTheme();
    const { t } = useTranslation("global");
    const UNASSIGNED_TAB = t("billing-section.tabs.unassigned-tab");
    const dispatcher = useAppDispatch();
    const [tabs, setTabs] = useState<TabData[]>([]);
    const activeBills = useAppSelector(state => state.billing.activeBills);
    const [currentTab, setCurrentTab] = useState<number>(0);

    const { filteredProducts: products, searchTxt,
        searchTheProduct, setCategoryTxt, categories } = useProductSearch();

    const handleAddTab = () => {
        setTabs([...tabs, {
            label: `${UNASSIGNED_TAB}`,
            uniqueTableName: "", floor: 0, no: 0
        }]);
        setCurrentTab(tabs.length);
    };

    const handleRemoveTab = (index: number) => {
        setTabs((prevTabs) => prevTabs.filter((tab, tabIndex) => tabIndex !== index));
        if (index === currentTab) {
            setCurrentTab(Math.max(0, index - 1));
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    const handleTableSelect = async (table: ITables) => {
        try {
            const { tableName, tableNo, floorNo } = table;
            const tableUniqueName = tableName.concat(String(tableNo)).concat(String(floorNo));
            const recordIndex = tabs?.findIndex(t => t.uniqueTableName === tableUniqueName);
            if (recordIndex !== -1) {
                handleRemoveTab(tabs.length - 1);
                setCurrentTab(recordIndex);
            } else {
                const tempTabs = [...tabs];
                tempTabs[currentTab].label = tableName;
                tempTabs[currentTab].no = tableNo;
                tempTabs[currentTab].floor = floorNo;
                tempTabs[currentTab].uniqueTableName = tableUniqueName;

                if(!activeBills[tableUniqueName]) {
                    dispatcher(createBillSession(tableUniqueName));
                }

                setTabs(tempTabs);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const EMPTY_TAB_STYLE = {
        width:"100%",
        height:"100%",
        backgroundImage: `url(${LW_POS_BLACK})`,
        backgroundSize: '45% 50%', // adjust the size to your liking
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',  
    }

    return (
        <Box sx={ tabs?.length > 0 ? {} :{
           ...EMPTY_TAB_STYLE 
        }}>
            <Grid container alignItems="center" bgcolor={theme.palette.CommonWhite.main} p={2}>
                <Grid item xs={12} md={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="dynamic tabs"
                        style={{ height: 50, width: "100%" }}
                    >
                        {tabs?.map((tab, index) => (
                            <Tab
                                key={index}
                                iconPosition="end"
                                sx={{ ...TAB_STYLES }}
                                icon={
                                    <IconButton
                                        size="large"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent triggering tab change when clicking the icon
                                            handleRemoveTab(index);
                                        }}>
                                        <DisabledByDefaultIcon color="error" sx={{ fontSize: 55 }} />
                                    </IconButton>
                                }
                                label={
                                    <Stack>
                                        <Stack flexDirection={"row"} alignItems={"center"}>
                                            {/* <Typography variant='body1'>{tab.label}</Typography> */}
                                            {/* <Chip label={tab.label} variant="outlined" /> */}
                                            {tab.no !== 0 && <Chip label={`NO ${tab.no}`} variant="outlined" />}
                                            {tab.no !== 0 && <Chip label={`Floor ${tab.floor}`} variant="outlined" />}
                                        </Stack>
                                        {tab.floor !== 0 && <Typography fontSize={12} fontWeight={"bold"}>{tab.label}</Typography>}
                                    </Stack>
                                }
                            />
                        ))}
                        <Button variant='contained' onClick={handleAddTab} startIcon={<ShoppingCartIcon />}
                            sx={{ borderRadius: 10, minWidth: "150px" }}>
                            NEW / OPEN
                        </Button>
                    </Tabs>
                    <Box mr={5}>
                        <SearchInput
                            value={searchTxt}
                            onChange={(txt) => searchTheProduct(txt)}
                            placeholder="Search by Name, Product Id, Category"
                        />
                    </Box>
                </Grid>
            </Grid>


            {tabs?.map((tab, index) => (
                currentTab === index && (
                    <Box key={index}>
                        {(tab.label === UNASSIGNED_TAB || tab.uniqueTableName === "") ? (
                            <TableAssigner onTableSelect={handleTableSelect} />
                        ) : (
                            <BillingScreen products={products} currentTab={tab.uniqueTableName}
                                setCategoryTxt={setCategoryTxt} categories={categories} />
                        )}
                    </Box>
                )
            ))}
        </Box>
    );
}

export { BillingInTabs };

