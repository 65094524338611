import React, { useState, useEffect } from "react";
import FormControl from '@mui/material/FormControl';


import { theme } from "../../Theme";
import { Checkbox, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@mui/material";


interface CustomDropDownProps {
  options: any[];
  bindValue: string;
  bindLabel:string;
  name:string;
  selectedValues:any[];
  onChange: (selected: string[],bindLabel:string) => void;
  label:string;
  placeholder:string;
  isMultiple:boolean
}

const CustomDropDown: React.FC<CustomDropDownProps> = ({ options, bindValue,bindLabel,name, onChange,selectedValues,label,isMultiple,placeholder }) => {


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
 
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
    ...theme,
  };

  const [selected, setSelected] = useState<string[]>(selectedValues);

  useEffect(() => {
    if(selectedValues.length){
      setSelected(selectedValues);
    }
  }, [selectedValues]);

  const isAllSelected = options.length > 0 && selected.length === options.length;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string[];
    if(!isMultiple){
      setSelected(value)
      onChange(value,name);
      return;
    }
    if (value[value.length - 1] === "all") {
      const newSelected = selected.length === options.length ? [] : options.map((opt) => opt[bindValue]);
      setSelected(newSelected);
      onChange(newSelected,name);
      return;
    }
    setSelected(value);
    onChange(value,name);
  };

  return (
    <FormControl sx={{ m: 1, width:'230px' }}>
      <InputLabel id="mutiple-select-label">{label}</InputLabel>
      <Select
        labelId="mutiple-select-label"
        style={{marginTop:'6px'}}
        multiple={isMultiple}
        fullWidth
        value={selected}
        //onChange={handleChange}
        variant="outlined"
        placeholder={placeholder}
        renderValue={(selected:any) =>
          selected.length ? options
            .filter((option) => selected.includes(option[bindValue]))
            .map((option) => option[bindLabel])
            .join(", "):placeholder
        }
        MenuProps={MenuProps as any}
      >
        {isMultiple && <MenuItem
          value="all"
        >
          <ListItemIcon>
            <Checkbox
              indeterminate={
                selected.length > 0 && selected.length < options.length-1
              }
              checked={isAllSelected}
            />
          </ListItemIcon>
          <ListItemText
            primary="Select All"
          />
        </MenuItem>
        }
        {options.map((option,index) => (
          <MenuItem key={index} value={option[bindValue]}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option[bindValue]) > -1} />
            </ListItemIcon>
            <ListItemText primary={option[bindLabel]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomDropDown;
