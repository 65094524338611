export enum TAX_TYPE {
  PERCENTAGE = "PERCENTAGE",
  CASH = "CASH",
}

export interface TaxComponent {
  taxId: string;
  taxName: string;
  applicableTax: number;
  taxType: TAX_TYPE;
  isActive: boolean;
  isDefault: boolean;
  startDate: string;
  endDate: string;
}

export type TaxComponents = {[key: string ] : TaxComponent};

export interface IClientTaxSettings {
  isTaxApplicable: boolean;
  applyDefaultTax: boolean;
  considerStrictProductSettings: boolean;
  activeTaxList: TaxComponents;
  deactiveTaxList: TaxComponents;
}
