import { AppConfig } from "../config";

let request: IDBOpenDBRequest;
let db: IDBDatabase;
let version = 1;


export enum OfflineStores {
  ActiveBills = "activeBills",
  Bills = 'bills'
}

export const initOfflineDB = (): Promise<boolean|IDBDatabase> => {
    
  return new Promise((resolve) => {
    request = indexedDB.open(AppConfig.getIndexDBName());

    // if the data object store doesn't exist, create it
    request.onupgradeneeded = () => {
      db = request.result;
      if (!db.objectStoreNames.contains(OfflineStores.Bills)) {
        console.log('Creating Bills store');
        db.createObjectStore(OfflineStores.Bills, { keyPath: 'sessionId' });
      }

      if (!db.objectStoreNames.contains(OfflineStores.ActiveBills)) {
        console.log('Creating ActiveBills store');
        db.createObjectStore(OfflineStores.ActiveBills, { keyPath: 'sessionId' });
      }
      // no need to resolve here
    };

    request.onsuccess = (e) => {
      db = request.result;
      // get current version and store it
      version = db.version;
      resolve(request.result);
    };

    request.onerror = (e) => {
      resolve(false);
    };
  });
};

export const addDataToOfflineDB = <T>(storeName: string, data: T): Promise<T|string|null> => {
  return new Promise((resolve) => {
    request = indexedDB.open(AppConfig.getIndexDBName(), version);

    request.onsuccess = () => {
      console.log('request.onsuccess - addData', data);
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      store.add(data);
      resolve(data);
    };

    request.onerror = () => {
      const error = request.error?.message
      if (error) {
        resolve(error);
      } else {
        resolve('Unknown error');
      }
    };
  });
};

export const deleteDataToOfflineDB = (storeName: string, key: string): Promise<boolean> => {
  return new Promise((resolve) => {
    request = indexedDB.open(AppConfig.getIndexDBName(), version);

    request.onsuccess = () => {
      console.log('request.onsuccess - deleteData', key);
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const res = store.delete(key);
      res.onsuccess = () => {
        resolve(true);
      };
      res.onerror = () => {
        resolve(false);
      }
    };
  });
};

export const updateDataToOfflineDB = <T>(storeName: string, key: string, data: T): Promise<T|string|null> => {
  return new Promise((resolve) => {
    request = indexedDB.open(AppConfig.getIndexDBName(), version);

    request.onsuccess = () => {
      console.log('request.onsuccess - updateData', key);
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const res = store.get(key);
      res.onsuccess = () => {
        const newData = { ...res.result, ...data };
        store.put(newData);
        resolve(newData);
      };
      res.onerror = () => {
        resolve(null);
      }
    };
  });
};

export const getOfflineStoreData = <T>(storeName: OfflineStores, recordKey:string|null = null): Promise<T[]> => {
  return new Promise((resolve) => {
    request = indexedDB.open(AppConfig.getIndexDBName());

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = recordKey ? store.get(recordKey): store.getAll();
      res.onsuccess = () => {
        resolve(res.result);
      };
    };
  });
};