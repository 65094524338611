import { Box, Link, Typography, useTheme } from '@mui/material';
import * as React from 'react';

interface FloorCardProps {
    floorName: string;
    selectedFloor: string;
    onClick: () => void;
}

const FloorCard: React.FC<FloorCardProps> = ({ floorName, onClick, selectedFloor }) => {
    const theme = useTheme();
    const isSelected = selectedFloor === floorName;
    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 2,
                width: "100%",
                height: "80px",
                //width: "150px",
                border: '1px solid #ddd',
                cursor: 'pointer',
                backgroundColor: isSelected ? theme.palette.primary.dark : theme.palette.secondary.light,
                color: theme.palette.secondary.contrastText,
                textOverflow: "ellipsis",
                '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText
                },
            }}

        >
            <Typography variant='button' sx={{
                width: "150px",
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>
               FLOOR {floorName}
            </Typography>
            <Link underline="none" onClick={onClick}>
                {' '}
            </Link>
        </Box>
    );
};

export { FloorCard };