import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAppDispatch } from '../../../redux-store/hooks';
import { createCategory } from '../../../services';


interface AddCategoryProps {
    open : boolean;
    toggleDailog: (val: boolean) => void;
}

const AddCategory:React.FC<AddCategoryProps> = ({open, toggleDailog}) => {
    const dispatcher = useAppDispatch();
    return (
            <Dialog
                open={open}
                onClose={toggleDailog}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const categoryName = formJson.category;
                        dispatcher(createCategory({category: {
                            categoryId: "",
                            categoryName: categoryName,
                        } }));
                        toggleDailog(false);
                    },
                }}
            >
                <DialogTitle>New Category</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                      Please enter the category name here which you want to assign for the products.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="category"
                        label="Category Name"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleDailog(false)}>Cancel</Button>
                    <Button type="submit">Create</Button>
                </DialogActions>
            </Dialog>
    );
}

export { AddCategory };