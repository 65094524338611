import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Autocomplete, Button, Checkbox, Grid, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { RestaurantTable } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { TABLE_STATUS } from '../../../all-types';
import { addRestaurantTable } from '../../../redux-store';

const CreateTableForm: React.FC<{}> = () => {
    const [tableName, setTableName] = useState('');
    const [tableNo, setTableNo] = useState(0);
    const [floorNo, setFloorNo] = useState(0);
    const [place, setPlace] = useState('');
    const [capacity, setCapacity] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const dispatcher = useAppDispatch();

    const places = useAppSelector(state => state.clientInfo.data.tableManager.places);
    const floors = useAppSelector(state => state.clientInfo.data.tableManager.noOffloors);
    const allFloors = Array.from({ length: floors }, (_, i) => i + 1);

    const handleTableNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTableName(event.target.value);
    };

    const handleTableNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTableNo(parseInt(event.target.value, 10));
    };

    const handleCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCapacity(parseInt(event.target.value, 10));
    };

    const handleIsActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked);
    };

    const createTable =async() => {
        try {
            dispatcher(addRestaurantTable({
                tableName, capacity, floorNo,
                isActive, place, tableId: "", tableNo, status: TABLE_STATUS.FREE 
            }));
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <Stack p={2} spacing={3}>
            {/* Header */}
            <Stack direction="row" alignItems="center" spacing={1}>
                <AddCircleOutlineIcon color='primary' />
                <Typography variant="h5">Create Table</Typography>
                {/* Submit button */}
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{ alignSelf: 'flex-start' }}
                    onClick={createTable}
                >
                    Save
                </Button>
            </Stack>

            {/* Form fields in a grid layout */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Table Name"
                        value={tableName}

                        onChange={handleTableNameChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Table No"
                        value={tableNo}
                        onChange={handleTableNoChange}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        value={floorNo}
                        onChange={(event, newValue) => setFloorNo(Number(newValue) || 1)}
                        options={allFloors}
                        renderInput={(params) => (
                            <TextField {...params} fullWidth label="Select Floor" variant="outlined" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        value={place}
                        onChange={(event, newValue) => setPlace(newValue || "")}
                        options={places}
                        renderInput={(params) => (
                            <TextField {...params} fullWidth label="Select Place" variant="outlined" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Capacity"
                        value={capacity}
                        onChange={handleCapacityChange}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={6} display="flex" alignItems="center">
                    <Checkbox
                        checked={isActive}
                        onChange={handleIsActiveChange}
                    />
                    <Typography>Is working</Typography>
                </Grid>
            </Grid>


            <Stack>
                <RestaurantTable key={2} table={{
                    tableName, capacity, floorNo,
                    isActive, place, tableId: "", tableNo, status: TABLE_STATUS.FREE
                }} />
            </Stack>
        </Stack>
    );
};

export { CreateTableForm };

