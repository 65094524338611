import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux-store/hooks";
import { assignFloor } from "../../../redux-store";


const AssignFloorForm = () => {
    const [noofFloors, setNoofFloors] = useState(1);
    const places = useAppSelector(state => state.clientInfo.data.tableManager.places);
    const dispatcher = useAppDispatch();

    const handleFloorNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNoofFloors(Number(event.target.value));
    };

    const handleAssigningFloor = async () => {
        try {
            dispatcher(assignFloor(noofFloors));
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Stack gap={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Assign Floor
            </Typography>
            <TextField
                label="Number of Floors"
                value={noofFloors}
                type="number"
                inputProps={{ min: 1 }}
                onChange={handleFloorNoChange}
            />
            <Button variant="contained" sx={{ width: 200, height: 60 }}
                onClick={handleAssigningFloor}>Assign</Button>
        </Stack>
    )
}

export { AssignFloorForm };
