import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import * as React from 'react';
import { useAppDispatch } from '../../../redux-store/hooks';
import { deleteProduct } from '../../../services';

interface CDeleteProductAlert {
    productId: string;

}

const DeleteProductAlert: React.FC<CDeleteProductAlert> = ({ productId }) => {
    const dispatcher = useAppDispatch();
    const [open, setOpen] = React.useState(false);
    const [productIdToDelete, setProductIdToDelete] = React.useState("");

    const deleteProductInList = () => {
        setProductIdToDelete(productId);
        setOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await dispatcher(deleteProduct(productIdToDelete));
            //TODO: Add Toast
            //console.log(response);
        } catch (e) {
            console.log(e);
        } finally {
            setOpen(false);
        }
    };


    return (
        <>
            <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => deleteProductInList()}
            />
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this product?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={handleDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export { DeleteProductAlert };
