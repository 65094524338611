import { Card, CardContent, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';

import { useAppSelector } from "../../redux-store/hooks";
import { CommonBtn } from "../../components";
import { useState } from "react";
import { AddCategory } from "./modules";

const Category = () => {
    const category = useAppSelector(state => state.categories.data);
    const STANDARD_HEADER_HEIGHT = useAppSelector((state) => state.ui.STANDARD_HEADER_HEIGHT);
    const PANEL_HEIGHT = `calc(100vh - ${STANDARD_HEADER_HEIGHT}px)`;
    const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);
    const toggleAddCategoryDailog = (val: boolean) => setIsAddCategoryOpen(val);

    return (
        <Stack sx={{ height: PANEL_HEIGHT }}>
            <Stack flex={1} alignItems={"flex-end"} p={1}>
                <CommonBtn variant="outlined" onClick={() => toggleAddCategoryDailog(true)}
                startIcon={<AddIcon/>}>New</CommonBtn>
            </Stack>
            <Stack flex={9} p={1}>
                <Stack sx={{
                    overflowY: 'auto', gap: 2, justifyContent: "center",
                    flexDirection: "row", flexWrap: "wrap"
                }}>
                    {category?.map(cat => (
                        <Stack key={cat.categoryId} sx={{
                            maxWidth: 200, height: 100,
                            border: "1px solid black", borderRadius: 2
                        }}>
                            <CardContent>
                                <Typography>{cat.categoryName}</Typography>
                            </CardContent>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
            <AddCategory open={isAddCategoryOpen} toggleDailog={toggleAddCategoryDailog}/>
        </Stack>
    )
}

export { Category };