import AddIcon from '@mui/icons-material/Add';
import EditIcon from "@mui/icons-material/Edit";
import { Grid, SwipeableDrawer, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";
import { ProductType } from '../../all-types';
import { CommonBtn } from '../../components';
import SearchInput from "../../components/Input/SearchInput/SearchInput";
import { useProductSearch } from '../../hooks';
import { AddProductPopup, DeleteProductAlert, EditProductPopup } from './modules';
import { useAppSelector } from '../../redux-store/hooks';


const Inventory: React.FC<{}> = () => {
    const { filteredProducts: allProducts, searchTheProduct, searchTxt } = useProductSearch();
    const STANDARD_HEADER_HEIGHT = useAppSelector((state) => state.ui.STANDARD_HEADER_HEIGHT);
    const [selectedProduct, setSelectedProduct] = useState<ProductType | null>(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const exitEditPopup = () => setOpenEdit(false);
    const exitAddPopup = () => setOpenAdd(false);

    const EditItemInTheList = (p: ProductType) => {
        setSelectedProduct(null);
        setSelectedProduct(p);
        setOpenEdit(true);
    }

    const columns: GridColDef[] = [
        {
            field: "image",
            headerName: "Image",
            flex: 0.5,
            renderCell: (params) => (
                <img src={params.value} alt={params.row.name} style={{ width: "50px", height: "50px" }} />
            ),
        },
        { field: "productCode", headerName: "Product Code", flex: 0.5 },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "category", headerName: "Category", flex: 1 },
        { field: "isInStock", headerName: "In Stock", flex: 0.5 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 0.5,
            renderCell: (params) => (
                <>
                    <GridActionsCellItem
                        icon={<div style={{ fontSize: 40, display: 'flex', alignItems: 'center' }}><EditIcon /></div>}
                        label="Edit"
                        onClick={() => EditItemInTheList(params.row)}
                    />
                    <DeleteProductAlert productId={params?.row?._id} />
                    {/* <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => deleteProductInList(params?.row?._id)}
                    /> */}
                </>
            ),
        },
    ];

    const rows = allProducts?.map((product) => ({
        _id: product?._id || product.productId,
        id: product.productId,
        productId: product.productId,
        productCode: product.productCode,
        name: product.name,
        description: product.description,
        category: product.category,
        categoryId: product.categoryId,
        image: product.image,
        defaults: product.defaults,
        rateCard: product.rateCard,
        isInStock: product.isInStock,
        currency: product.currency,
        tax: product.tax,
        discounts: product.discounts
    }));

    const PANEL_HEIGHT = `calc(100vh - ${STANDARD_HEADER_HEIGHT}px)`;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: PANEL_HEIGHT}}>
            <Grid container style={{
                width: '100%', padding: 10, backgroundColor: '#fff',
                zIndex: 1, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', justifyContent: "space-between"
            }}>
                <Grid item xs={12} md={6} style={{ padding: 10 }}>
                    <SearchInput
                        value={searchTxt}
                        onChange={(txt) => searchTheProduct(txt)}
                        placeholder="Search by name, itemId, Category"
                    />
                </Grid>
                {/* <Grid item xs={12} md={4} style={{ padding: 10 }} display={"flex"} alignItems={"center"}>
                </Grid> */}
                <Grid item xs={12} md={6} style={{ padding: 10 }} display={"flex"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
                    <Typography variant="body1">Total {allProducts?.length} Items</Typography>
                    <CommonBtn variant="outlined" startIcon={<AddIcon />} onClick={() => setOpenAdd(true)}>
                        New
                    </CommonBtn>
                    {/* <Button variant="contained" startIcon={<AddIcon />}>
                        Add Category
                    </Button> */}
                </Grid>
            </Grid>
            <div style={{ flex: 1, overflow: 'auto' }}>
                <DataGrid rows={rows} columns={columns} />
            </div>

            <SwipeableDrawer
                anchor={"right"}
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                onOpen={() => setOpenEdit(true)}
            >
                <Grid container width={600} mt={"64px"}>
                    <Grid item xs={12} md={12}>
                        {selectedProduct && (
                            <EditProductPopup key={selectedProduct?._id}
                                editProduct={selectedProduct} exit={exitEditPopup} />
                        )}
                    </Grid>
                </Grid>
            </SwipeableDrawer>

            <SwipeableDrawer
                anchor={"right"}
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                onOpen={() => setOpenAdd(true)}
            >
                <Grid container width={600} mt={"64px"}>
                    <Grid item xs={12} md={12}>
                        <AddProductPopup exit={exitAddPopup} />
                    </Grid>
                </Grid>
            </SwipeableDrawer>

        </div>
    );
};

export { Inventory };

