import { Button, Checkbox, MenuItem, Paper, Select, SelectChangeEvent, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from '@mui/material';
import uuid from "react-uuid";
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { ChangeEvent, useState } from 'react';
import { TAX_TYPE, TaxComponent } from '../../../all-types';
import { addTaxComponent } from '../../../redux-store';


const defaultTaxOptions: TaxComponent = {
	taxId: '',
	taxName: '',
	applicableTax: 0,
	taxType: TAX_TYPE.PERCENTAGE,
	isActive: false,
	isDefault: false,
	startDate: '',
	endDate: ''
}

const ClientTaxList = () => {
	const taxes = useAppSelector(state => state.clientInfo.data.taxManager?.activeTaxList);
	const deactivatedList = useAppSelector(state => state.clientInfo.data.taxManager?.deactiveTaxList);

	const dispatcher = useAppDispatch();
	const [value, setValue] = useState(0);

	const [newTax, setNewTax] = useState<TaxComponent>(defaultTaxOptions);

	const addTaxToList = async () => {
		try {
			await dispatcher(addTaxComponent({
				...newTax, taxId: uuid(),
				startDate: String(new Date().getTime())
			}));
			setNewTax(defaultTaxOptions);
		} catch (e) {
			console.error(e);
		}
	}

	const handleTaxTypeChange = (event: SelectChangeEvent<TAX_TYPE>) => {
		const { value } = event.target;
		setNewTax({ ...newTax, taxType: value as TAX_TYPE });
	};

	const handleIsActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewTax({ ...newTax, isActive: event.target.checked });
	};

	const handleIsDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewTax({ ...newTax, isDefault: event.target.checked });
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = event.target;
		setNewTax({ ...newTax, [name]: value });
	};

	// return (
	// 	<TableContainer component={Paper} sx={{marginTop: 2}}>
	// 		<Table sx={{ minWidth: 650 }} aria-label="simple table">
	// 			<TableHead>
	// 				<TableRow>
	// 					<TableCell>Tax ID</TableCell>
	// 					<TableCell>Tax Name</TableCell>
	// 					<TableCell>Applicable Tax (%/Cash)</TableCell>
	// 					<TableCell>Tax Type</TableCell>
	// 					<TableCell>Is Active</TableCell>
	// 					<TableCell>Is Default</TableCell>
	// 					<TableCell>Start Date</TableCell>
	// 					<TableCell>End Date</TableCell>
	// 				</TableRow>
	// 			</TableHead>
	// 			<TableBody>
	// 				{Object.values(taxes)?.map((tax) => (
	// 					<TableRow key={tax.taxId}>
	// 						<TableCell>{tax.taxId}</TableCell>
	// 						<TableCell>{tax.taxName}</TableCell>
	// 						<TableCell>{tax.applicableTax}</TableCell>
	// 						<TableCell>{tax.taxType}</TableCell>
	// 						<TableCell>{tax.isActive ? 'Yes' : 'No'}</TableCell>
	// 						<TableCell>{tax.isDefault ? 'Yes' : 'No'}</TableCell>
	// 						<TableCell>{tax.startDate}</TableCell>
	// 						<TableCell>{tax.endDate}</TableCell>
	// 					</TableRow>
	// 				))}
	// 			</TableBody>
	// 			<TableBody>
	// 				<TableRow>
	// 					<TableCell>
	// 						Auto
	// 					</TableCell>
	// 					<TableCell>
	// 						<TextField
	// 							name="taxName"
	// 							value={newTax.taxName}
	// 							onChange={handleInputChange}
	// 							placeholder="Enter Tax Name"
	// 						/>
	// 					</TableCell>
	// 					<TableCell>
	// 						<TextField
	// 							name="applicableTax"
	// 							value={newTax.applicableTax}
	// 							onChange={handleInputChange}
	// 							placeholder="Enter Applicable Tax"
	// 						/>
	// 					</TableCell>
	// 					<TableCell>
	// 						<Select
	// 							value={newTax.taxType}
	// 							onChange={handleTaxTypeChange}
	// 							displayEmpty
	// 							inputProps={{ 'aria-label': 'Without label' }}
	// 						>
	// 							<MenuItem value={TAX_TYPE.PERCENTAGE}>PERCENTAGE</MenuItem>
	// 							<MenuItem value={TAX_TYPE.CASH}>CASH</MenuItem>
	// 						</Select>
	// 					</TableCell>
	// 					<TableCell>
	// 						<Checkbox
	// 							name="isActive"
	// 							checked={newTax.isActive}
	// 							onChange={handleIsActiveChange}
	// 						/>
	// 					</TableCell>
	// 					<TableCell>
	// 						<Checkbox
	// 							name="isDefault"
	// 							checked={newTax.isDefault}
	// 							onChange={handleIsDefaultChange}
	// 						/>
	// 					</TableCell>
	// 					<TableCell>
	// 						Auto
	// 					</TableCell>
	// 					<TableCell>
	// 						Auto
	// 					</TableCell>
	// 				</TableRow>
	// 			</TableBody>
	// 		</Table>
	// 		<Stack alignItems={"flex-end"}>
	// 			<Button variant='contained' sx={{
	// 				width: 200, height: 60,
	// 				margin: 2
	// 			}}
	// 				onClick={addTaxToList}>Add</Button>
	// 		</Stack>
	// 	</TableContainer>
	// );


    const tabContent = [
        <Stack key="Active">
           		<TableContainer component={Paper} sx={{marginTop: 2}}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Tax ID</TableCell>
						<TableCell>Tax Name</TableCell>
						<TableCell>Applicable Tax (%/Cash)</TableCell>
						<TableCell>Tax Type</TableCell>
						<TableCell>Is Active</TableCell>
						<TableCell>Is Default</TableCell>
						<TableCell>Start Date</TableCell>
						<TableCell>End Date</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.values(taxes)?.map((tax) => (
						<TableRow key={tax.taxId}>
							<TableCell>{tax.taxId}</TableCell>
							<TableCell>{tax.taxName}</TableCell>
							<TableCell>{tax.applicableTax}</TableCell>
							<TableCell>{tax.taxType}</TableCell>
							<TableCell>{tax.isActive ? 'Yes' : 'No'}</TableCell>
							<TableCell>{tax.isDefault ? 'Yes' : 'No'}</TableCell>
							<TableCell>{tax.startDate}</TableCell>
							<TableCell>{tax.endDate}</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableBody>
					<TableRow>
						<TableCell>
							Auto
						</TableCell>
						<TableCell>
							<TextField
								name="taxName"
								value={newTax.taxName}
								onChange={handleInputChange}
								placeholder="Enter Tax Name"
							/>
						</TableCell>
						<TableCell>
							<TextField
								name="applicableTax"
								value={newTax.applicableTax}
								onChange={handleInputChange}
								placeholder="Enter Applicable Tax"
							/>
						</TableCell>
						<TableCell>
							<Select
								value={newTax.taxType}
								onChange={handleTaxTypeChange}
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
							>
								<MenuItem value={TAX_TYPE.PERCENTAGE}>PERCENTAGE</MenuItem>
								<MenuItem value={TAX_TYPE.CASH}>CASH</MenuItem>
							</Select>
						</TableCell>
						<TableCell>
							<Checkbox
								name="isActive"
								checked={newTax.isActive}
								onChange={handleIsActiveChange}
							/>
						</TableCell>
						<TableCell>
							<Checkbox
								name="isDefault"
								checked={newTax.isDefault}
								onChange={handleIsDefaultChange}
							/>
						</TableCell>
						<TableCell>
							Auto
						</TableCell>
						<TableCell>
							Auto
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Stack alignItems={"flex-end"}>
				<Button variant='contained' sx={{
					width: 200, height: 60,
					margin: 2
				}}
					onClick={addTaxToList}>Add</Button>
			</Stack>
		</TableContainer>
        </Stack>,
        <Stack key="Deactivated">
           		<TableContainer component={Paper} sx={{marginTop: 2}}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Tax ID</TableCell>
						<TableCell>Tax Name</TableCell>
						<TableCell>Applicable Tax (%/Cash)</TableCell>
						<TableCell>Tax Type</TableCell>
						<TableCell>Is Active</TableCell>
						<TableCell>Is Default</TableCell>
						<TableCell>Start Date</TableCell>
						<TableCell>End Date</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.values(deactivatedList)?.map((tax) => (
						<TableRow key={tax.taxId}>
							<TableCell>{tax.taxId}</TableCell>
							<TableCell>{tax.taxName}</TableCell>
							<TableCell>{tax.applicableTax}</TableCell>
							<TableCell>{tax.taxType}</TableCell>
							<TableCell>{tax.isActive ? 'Yes' : 'No'}</TableCell>
							<TableCell>{tax.isDefault ? 'Yes' : 'No'}</TableCell>
							<TableCell>{tax.startDate}</TableCell>
							<TableCell>{tax.endDate}</TableCell>
						</TableRow>
					))}
				</TableBody>

			</Table>
		</TableContainer>
        </Stack>
		]

    return (
        <div>
            <Tabs value={value} onChange={(e, v) => setValue(v)}>
                <Tab label="Active" aria-label="Profile Settings" />
                <Tab label="Deactivated" aria-label="Printer Settings" />
            </Tabs>
            {tabContent[value]}
        </div>
    );
};

export { ClientTaxList };
