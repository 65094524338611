import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton, Theme, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { ICategoryType, ProductType } from '../../all-types';
import { STYLE_CONSTANTS } from '../../constants/styles';
import { CartPanel, CategoryPanel, ProductPanel } from './panels';
import { CartItemsSummary, CartPanelItemDetails, CartPanelItems } from './modules';
import { useAppSelector } from '../../redux-store/hooks';
import { PaymentMethod } from './components';

const EXTRA_HEIGHT = "80px";

const styles = {
  billingScreen: (theme: Theme, PANEL_HEIGHTS: string) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: PANEL_HEIGHTS,
    justifyContent: 'space-between',
  }),
  categoryPanel: (theme: Theme, PANEL_HEIGHTS: string) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth: "180px",
    maxWidth: "200px",
    width: STYLE_CONSTANTS.BILLING_PANEL_CATEGORY,
    height: PANEL_HEIGHTS,
    overflowY: 'auto',
    borderRight: `1px solid ${theme.palette.divider}`,
    scrollbarWidth: 'thin',
  }),
  summaryPanel: (theme: Theme, isCartPanelOpen: boolean, PANEL_HEIGHTS: string) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: "100%",
    height: PANEL_HEIGHTS,
    overflowY: 'auto',
    //borderRight: `1px solid ${theme.palette.divider}`,
    scrollbarWidth: 'thin',
    position: "relative"
  }),
  productPanel: (theme: Theme, isCartPanelOpen: boolean, PANEL_HEIGHTS: string) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: isCartPanelOpen ? "60%" : STYLE_CONSTANTS.BILLING_PANEL_ITEMS,
    height: PANEL_HEIGHTS,
    overflowY: 'auto',
    borderRight: `1px solid ${theme.palette.divider}`,
    scrollbarWidth: 'thin',
    position: "relative"
  }),
  rightPanel: (theme: Theme, isCartPanelOpen: boolean, PANEL_HEIGHTS: string) => ({
    height: PANEL_HEIGHTS,
    display: 'flex',
    //maxWidth: "300px",
    flexDirection: "column",
    justifyContent: isCartPanelOpen ? 'end' : 'center',
    alignItems: 'start',
    borderLeft: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    transition: 'width 0.5s ease',
    width: "30%",
    minWidth: "400px"
  }),
  toggleButton: {
    position: 'absolute',
    top: EXTRA_HEIGHT,
    right: 0,
    transform: 'translate(-6%, -0%)',
  },
};

interface IBillingScreenProps {
  products: ProductType[];
  categories: ICategoryType[];
  currentTab: string;
  setCategoryTxt: (txt: string) => void;
}

const BillingScreen: FC<IBillingScreenProps> = ({ products, setCategoryTxt, categories, currentTab }) => {
  const theme = useTheme();
  const HEADER_HEIGHT = useAppSelector(state => state.ui.HEADER_HEIGHT);
  const allProducts = useAppSelector((state) => state.allproducts.data);

  const [isCartPanelOpen, setIsCartPanelOpen] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<ProductType | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [showBillScreen, setShowBillScreen] = useState(false);


  const PANEL_HEIGHTS = `calc(100vh - ${HEADER_HEIGHT}px - ${STYLE_CONSTANTS.DYNAMIC_TAB_HEIGHT_IN_PX} - ${STYLE_CONSTANTS.DYNAMIC_TAB_PADDING_IN_PX})`;

  const handleOpenPopUp = (p: ProductType) => {
    try {
      setSelectedProduct(p);
      setIsPopupOpen(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleClosePopUp = () => {
    setIsPopupOpen(false);
    setEditIndex(null);
  };

  const toggleCartPanel = () => {
    setIsCartPanelOpen(!isCartPanelOpen);
  };

  const handleEditCartItem = (productId: string, editIndex: number) => {
    try {
      const product = allProducts.find(p => p.productId === productId);
      if (product) {
        setEditIndex(editIndex);
        handleOpenPopUp(product);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Box sx={styles.billingScreen(theme, PANEL_HEIGHTS)}>
      {showBillScreen ? (
        <Box sx={styles.summaryPanel(theme, isCartPanelOpen, PANEL_HEIGHTS)}>
          <CartItemsSummary currentTab={currentTab} />
        </Box>
      ) : (
        <>
          {/* CATEGORY PANEL */}
          <Box sx={styles.categoryPanel(theme, PANEL_HEIGHTS)}>
            <CategoryPanel setCategoryTxt={setCategoryTxt} categories={categories} />
          </Box>

          {/* PRODUCT PANEL */}
          <Box sx={styles.productPanel(theme, isCartPanelOpen, PANEL_HEIGHTS)}>
            <ProductPanel products={products} currentTab={currentTab}
              selectedProduct={selectedProduct} isPopupOpen={isPopupOpen}
              openPopUp={handleOpenPopUp} closePopUp={handleClosePopUp}
              editIndex={editIndex}
            />
          </Box>
        </>
      )}

      {/* CART PANEL */}
      <IconButton size='large' sx={[styles.toggleButton, { backgroundColor: theme.palette.primary.dark }]} onClick={toggleCartPanel} color='warning' >
        {isCartPanelOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
      {isCartPanelOpen && (
        showBillScreen ? (
          <Box sx={styles.rightPanel(theme, isCartPanelOpen, PANEL_HEIGHTS)}>
            <PaymentMethod currentTab={currentTab}/>
            <CartPanelItemDetails goBack={() => setShowBillScreen(false)}isSummary={true} currentTab={currentTab} showBillingScreen={() => setShowBillScreen(true)} />
          </Box>
        ) : (
          <Box sx={styles.rightPanel(theme, isCartPanelOpen, PANEL_HEIGHTS)}>
            <CartPanelItems currentTab={currentTab} handleItemEdit={handleEditCartItem} />
            <CartPanelItemDetails isSummary={false} currentTab={currentTab} showBillingScreen={() => setShowBillScreen(true)} />
          </Box>
        )
      )}
    </Box>
  );
};

export { BillingScreen };
