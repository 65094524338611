export const getCurrentTimeInfo = () => {
  const now = new Date()

  const date = now.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
  const time = now.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
  const timestamp = now.getTime()
  const zones = now?.toString().match(/\((.+)\)/)
  const timezone = zones && zones.length && zones[1] // Extract timezone from Date object
  const year = now.getFullYear()

  return {
    date,
    time,
    timestamp,
    timezone,
    year,
  }
}
