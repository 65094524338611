import { Stack } from "@mui/material";
import { useAppSelector } from "../../../redux-store/hooks";
import { PlacesCard } from "./places-card";


const PlacesViewer = () => {
    const places = useAppSelector(state => state.clientInfo.data.tableManager.places);
    return (
        <Stack flexDirection={"column"}>
            {places?.map(p => (
                <PlacesCard key={p} onClick={() => console.log("clicked")}
                    placeName={p} selectedPlace={p} />
            ))}
        </Stack>
    )
}

export { PlacesViewer };
