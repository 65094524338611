import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import { ITables } from '../../all-types';
import { getStylesBySize } from './styles-util';

interface TableProps {
    table: ITables
    size?: "SMALL" | "MEDIUM" | "LARGE"
    onTableSelect?: (table: ITables) =>  void;
}

const RestaurantTable: React.FC<TableProps> = ({ table, size = "SMALL", onTableSelect }) => {
    const { tableName, tableNo, floorNo, capacity, place } = table;
    const { TABLE_MIN_WIDTH, CHAIR_SIZE, TABLE_HEIGHT,
        TABLE_CHAIR_DISTANCE, TABLE_NUMBER_FONT_SIZE, OTHER_FONT_SIZE } = getStylesBySize(size);
    const tableWidth = Math.max(TABLE_MIN_WIDTH, capacity * (CHAIR_SIZE / 2)); // Increase the table size based on the number of chairs


    const handleTableSelection = () => {
        try {
            if(onTableSelect) {
                onTableSelect(table);
            }
        } catch(e) {
            console.error(e);
        }
    }

    return (
        <Stack width={"100%"}  onClick={handleTableSelection}
          sx={{border:"1px solid black", borderRadius: 5, padding:1}}>
            
            {/* Top chairs */}
            {capacity > 0 && (
                <Stack flexDirection={"row"} justifyContent={"center"} sx={{ width: "100%" }}>
                    {[...Array(Math.ceil(capacity / 2))].map((_, index) => (
                        <EventSeatIcon key={index} sx={{ fontSize: `${CHAIR_SIZE}px` }} />
                    ))}
                </Stack>
            )}

            {/* Middle table */}
            <Stack
                sx={{
                    height: TABLE_HEIGHT,
                    backgroundColor: "grey",
                    width: `${tableWidth}px`, // Dynamically adjust table width
                    borderRadius: 40,
                    justifyContent: "center",
                    alignItems: "center",
                    margin: `${TABLE_CHAIR_DISTANCE}px auto`, // Center the table
                }}
            >
                <Typography variant='body1' fontWeight={"bold"} fontSize={`${OTHER_FONT_SIZE}px`}>{tableName}</Typography>
                <Typography variant='h2' fontWeight={"bold"} fontSize={`${TABLE_NUMBER_FONT_SIZE}px`}>{tableNo || ""}</Typography>
                <Typography variant='body1' fontWeight={"bold"} fontSize={`${OTHER_FONT_SIZE}px`}>{place}</Typography>

            </Stack>

            {/* Bottom chairs */}
            {capacity > 0 && (
                <Stack flexDirection={"row"} justifyContent={"center"}>
                    {[...Array(Math.floor(capacity / 2))].map((_, index) => (
                        <EventSeatIcon key={index} sx={{ fontSize: `${CHAIR_SIZE}px`, transform: "rotate(180deg)" }} />
                    ))}
                </Stack>
            )}
        </Stack>
    );
};

export { RestaurantTable };
