import { Autocomplete, Checkbox, Chip, FormControlLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IProductTaxSetting, TaxComponent, TaxComponents } from '../../../all-types';
import { useAppSelector } from '../../../redux-store/hooks';
import { getStortType } from '../../../utils';

interface IProductTax {
    data: IProductTaxSetting;
    onChange: (data: IProductTaxSetting) => void;
}

const ProductTax: FC<IProductTax> = ({
    data, onChange
}) => {
    const taxComponents: TaxComponents = useAppSelector(state => state.clientInfo.data?.taxManager?.activeTaxList);
    const [tempData, setTempData] = useState(data);

    const getApplicableTaxValue = (): TaxComponent[] => {
        let val: TaxComponent[] = [];
        try {
            const { appliedComponent } = tempData;
            if (appliedComponent && appliedComponent.length > 0) {
                const allTaxCompList = Object.values(taxComponents);
                if (allTaxCompList && allTaxCompList.length > 0) {
                    appliedComponent.forEach(c => {
                        const taxComp = allTaxCompList.find(t => t.taxId === c);
                        if (taxComp) {
                            val.push(taxComp);
                        }
                    });
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            return val;
        }
    }

    const [appliedComps, setAppliedComps] = useState(getApplicableTaxValue());

    const updateApplicableTax = (newValue: TaxComponent[]) => {
        try {
            const tempComps = [...newValue];
            setAppliedComps(tempComps);
            const temp = {
                ...tempData,
                appliedComponent: newValue.map(option => option.taxId)
            }
            setTempData(temp);
            updateDataToParent(temp);
        }
        catch (e) {
            console.error(e);
        }
    }

    const updateDataToParent = (newData: IProductTaxSetting) => {
        if (onChange) {
            onChange(newData);
        }
    }

    return (
        <Stack>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={tempData.enableStrictRules}
                name="radio-buttons-group"
                onChange={(e) => {
                    const temp = { ...tempData, enableStrictRules: e.target.value === 'true',
                         applyDefaultTax: e.target.value === 'false'};
                    setTempData(temp);
                    updateDataToParent(temp);
                }}
            >
                <FormControlLabel value="false" control={<Radio />} label="Apply Default Tax" />
                <FormControlLabel value="true" control={<Radio />} label="Enforce Product Tax" />
            </RadioGroup>
            {tempData.enableStrictRules && (
                <Stack ml={4}>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tempData.isTaxApplicable}
                                onChange={(e) => {
                                    const temp = { ...tempData, isTaxApplicable: e.target.checked };
                                    setTempData(temp);
                                    updateDataToParent(temp);
                                }}
                                name="isTaxApplicable"
                            />
                        }
                        label="Is Tax Applicable"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tempData.applyDefaultTax}
                                onChange={(e) => {
                                    const temp = { ...tempData, applyDefaultTax: e.target.checked };
                                    setTempData(temp);
                                    updateDataToParent(temp);
                                }}
                                name="applyDefaultTax"
                            />
                        }
                        disabled={!tempData.isTaxApplicable}
                        label="Apply Default Tax"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tempData.enableProductTax}
                                onChange={(e) => {
                                    const temp = { ...tempData, enableProductTax: e.target.checked };
                                    setTempData(temp);
                                    updateDataToParent(temp);
                                }}
                                name="enableProductTax"
                            />
                        }
                        disabled={!tempData.isTaxApplicable}
                        label="Enable Product Tax"
                    />

                    <Stack>
                        <Autocomplete
                            multiple
                            id="fixed-tags-demo"
                            value={appliedComps}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                updateApplicableTax(newValue);
                            }}
                            options={Object.values(taxComponents)}
                            getOptionLabel={(option) => option.taxName}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    return (
                                        <Chip
                                            key={key}
                                            label={<span>
                                                {option.taxName}
                                                <small>({option.applicableTax} {getStortType(option.taxType)})</small>
                                            </span>}
                                            {...tagProps}
                                        />
                                    );
                                })
                            }
                            style={{ width: 500 }}
                            disabled={!tempData.isTaxApplicable || !tempData.enableProductTax}
                            renderInput={(params) => (
                                <TextField {...params} label="Applicable Tax" placeholder="Tax Components" />
                            )}
                        />
                    </Stack>
                </Stack>
            )}

        </Stack>
    );
};

export { ProductTax };
