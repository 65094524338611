import { DISCOUNT_TYPE, TAX_TYPE } from "../all-types";


export const getStortType = (TYPE: string) => {
    switch(TYPE) {
        case DISCOUNT_TYPE.PERCENTAGE:
        case TAX_TYPE.PERCENTAGE:
            return '%';
        default:
            return TYPE;
    }
}