import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Items from '../Pages/Items/Items';
import { Layout } from '../layout';
import { BillingScreen, Category, Inventory, LoginScreen, Settings } from '../screens';
import DashboardPrivateRoute from './dashboard-route';
import { BillingInTabs } from '../screens/billing/billing-in-tabs';
import Business from '../screens/Business/Business';

interface ComponentProps {
    isLoaderActive: boolean;
}

const AppRoutes: FC<ComponentProps> = ({ isLoaderActive }) => {
    return (
        <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/app" element={
                <DashboardPrivateRoute isLoaderActive={isLoaderActive}>
                    <Layout isLoaderActive={isLoaderActive} />
                </DashboardPrivateRoute>
            }>
                <Route index element={<Dashboard />} />
                <Route path="settings" element={<Settings />} />
                <Route path="order" element={<BillingInTabs />} />
                <Route path="inventory" element={<Inventory />} />
                <Route path="category" element={<Category />} />

                <Route path="business" element={<Business />} />
                {/* <Route path="reports" element={<Items handleOpen={() => console.log("test")} />} /> */}
            </Route>
            <Route path="*" element={<Navigate to="/app" replace />} />
        </Routes>
    );
};

export { AppRoutes };

