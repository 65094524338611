import { Stack } from "@mui/material";
import { useAppSelector } from "../../../redux-store/hooks";
import { FloorCard } from "./floor-card";


const FloorViewer = () => {
    const floors = useAppSelector(state => state.clientInfo.data.tableManager.noOffloors);
    return (
        <Stack flexDirection={"column"}>
            {Array.from({ length: floors }, (_, i) => i + 1)?.map(p => (
                <FloorCard key={p} onClick={() => console.log("clicked")}
                    floorName={String(p)} selectedFloor={String(p)} />
            ))}
        </Stack>
    )
}

export { FloorViewer };