import { Box, Grid, Modal } from '@mui/material';
import * as React from 'react';
import { ProductType } from '../../../all-types';
import { ProductPopup } from '../components';
import ProductCard from '../components/product-card';

interface IProductPanelProps {
    products: ProductType[]
    currentTab: string;
    selectedProduct: ProductType | null;
    isPopupOpen: boolean;
    openPopUp: (p: ProductType) => void
    closePopUp: () => void;
    editIndex: number | null
}

const ProductPanel: React.FC<IProductPanelProps> = ({ products, currentTab, 
    selectedProduct, isPopupOpen, openPopUp , closePopUp, editIndex}) => {

    const popupStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        boxShadow: 24,
    };

    return (
        <>
            <Grid container spacing={1} p={0.5}>
                {products?.map((product) => (
                    <Grid item key={product.productId} xs={12} sm={12} md={6} lg={3}
                        display={"flex"} justifyContent={"center"}>
                        <ProductCard item={product} handleOpen={openPopUp} />
                    </Grid>
                ))}
            </Grid>
            <Modal open={isPopupOpen} onClose={closePopUp}>
                <Box sx={{ ...popupStyle }}>
                    {selectedProduct && (
                        <ProductPopup product={selectedProduct}  exit={closePopUp} 
                        currentTab={currentTab} editIndex={editIndex}/>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export { ProductPanel };

