import { BillItem } from '../../all-types'

export const defaultBIDiscounts = {
  amountAfterDiscount: 0,
  amountBeforeDiscount: 0,
  discountAmount: 0,
  discountsInfo: [],
  isDiscountApplied: false,
}

export const defaultBillItem: BillItem = {
  productId: '',
  sno: 0,
  itemName: '',
  itemImage:"",
  quantity: 0,
  mrp: 0,
  amount: 0,
  noOfItems: 1,
  quantityType: '',
  quantityTypeShort: '',
  rateCardName: "",
  default: {
    defaultPrice: 0,
    defaultQuantityType: '',
    defaultQuantityTypeShort: '',
  },
  billingInfo: {
    discounts: defaultBIDiscounts,
    tax: {
      amountAfterTax: 0,
      amountBeforeTax: 0,
      isTaxApplied: true,
      taxAmount: 0,
      taxInfo: [],
    },
    finalAmount: 0,
    rawAmount: 0,
    totalDiscountGiven: 0,
    totalTaxCharged: 0,
  },
}
