import { Button, Stack, TextField, Typography } from "@mui/material"
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux-store/hooks";
import { addPlace } from "../../../redux-store";


const CreatePlaceForm = () => {
    const [placeName, setPlaceName] = useState('');
    const places = useAppSelector(state => state.clientInfo.data.tableManager.places);
    const dispatcher = useAppDispatch();


    const handleTableNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlaceName(event.target.value);
    };

    const handlePlaceCreation = async () => {
        try {
            if (places.indexOf(placeName) === -1) {
                dispatcher(addPlace(placeName));
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Stack gap={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Create a Place
            </Typography>
            <TextField
                label="Place Name"
                value={placeName}
                onChange={handleTableNameChange}
            />
            <Button variant="contained" sx={{ width: 200, height: 60 }}
                onClick={handlePlaceCreation}>Create</Button>
        </Stack>
    )
}

export { CreatePlaceForm };