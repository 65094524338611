import { Box, Link, Typography, useTheme } from '@mui/material';
import * as React from 'react';

interface PlacesCardProps {
    placeName: string;
    selectedPlace: string;
    onClick: () => void;
}

const PlacesCard: React.FC<PlacesCardProps> = ({ placeName, onClick, selectedPlace }) => {
    const theme = useTheme();
    const isSelected = selectedPlace === placeName;
    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 2,
                width: "100%",
                height: "80px",
                //width: "150px",
                border: '1px solid #ddd',
                cursor: 'pointer',
                backgroundColor: isSelected ? theme.palette.secondary.main : theme.palette.secondary.light,
                color: theme.palette.secondary.contrastText,
                textOverflow: "ellipsis",
                '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.contrastText
                },
            }}

        >
            <Typography variant='button' sx={{
                width: "150px",
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>
                {placeName}
            </Typography>
            <Link underline="none" onClick={onClick}>
                {' '}
            </Link>
        </Box>
    );
};

export { PlacesCard };
