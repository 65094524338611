import EmailIcon from '@mui/icons-material/Email';
import PrintIcon from '@mui/icons-material/Print';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { CircularProgress, Drawer, Stack, Typography } from '@mui/material';

import * as React from 'react';
import { LargeBtn } from '../../../components';


interface ShareBillDrawerProps {
    open: boolean;
    toggleDrawer: (v: boolean) => void;
    isLoading: boolean
}

const BillProcessDrawer: React.FC<ShareBillDrawerProps> = ({ open, toggleDrawer, isLoading }) => {

    return (
        <Drawer
            anchor="bottom"
            open={open}
        //onClose={() => toggleDrawer(false)}
        >
            {isLoading ? (
                <Stack sx={{ height: "50vh", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress size="3rem" />
                </Stack>
            ) : (
                <Stack sx={{ height: "50vh" }} justifyContent={"space-between"}>
                    <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"} gap={2} p={2}>
                        <LargeBtn
                            variant="outlined"
                            color="primary"
                            size="large"
                        >
                            <PrintIcon sx={{ fontSize: 60 }} />
                            <Typography variant="h6">Print</Typography>
                        </LargeBtn>

                        <LargeBtn
                            variant="outlined"
                            color="primary"
                            size="large"
                        >
                            <EmailIcon sx={{ fontSize: 60 }} />
                            <Typography variant="h6">Email</Typography>
                        </LargeBtn>

                        <LargeBtn
                            variant="outlined"
                            color="primary"
                            size="large"
                        >
                            <WhatsAppIcon sx={{ fontSize: 60 }} />
                            <Typography variant="h6">Whatsapp</Typography>
                        </LargeBtn>
                    </Stack>

                    <Stack alignItems={"flex-end"}>

                        <LargeBtn
                            variant="contained"
                            color="error"
                            size="large"
                            onClick={() => toggleDrawer(false)}
                        >
                            <CancelPresentationIcon sx={{ fontSize: 60 }} />
                            <Typography variant="h6">Close</Typography>
                        </LargeBtn>
                    </Stack>

                </Stack>
            )}
        </Drawer>
    );
}

export { BillProcessDrawer };

