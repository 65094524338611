
class AppConfig {

    static getBaseURL = () => {
        return process.env.REACT_APP_API_BASE_URL;
    }

    static getCLientId = () => {
        return process.env.REACT_APP_CLIENT_ID;
    }
    static getEmailBaseUrl = () => {
        return process.env.REACT_APP_EMAIL_BASE_URL;
    }

    static getEmailApiKey = () => {
        return process.env.REACT_APP_EMAIL_API_KEY;
    }

    static getIndexDBName = () => {
        return process.env.REACT_INDEX_DB_NAME || "lightworks-db";
    }
}

export {AppConfig};