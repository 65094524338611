export enum TABLE_STATUS {
  FREE = 'FREE',
  OCCUPIED = 'OCCUPIED',
  RESERVED = 'RESERVED',
}

export interface ITables {
  tableNo: number
  tableName: string
  tableId: string
  floorNo: number
  place: string
  capacity: number
  isActive: Boolean
  status: TABLE_STATUS
}

export interface IClientTableManager {
  tables: {[key:string] : ITables}
  places: Array<string> // eg: INDOOR, OUTDOOR, POOL_VIEW
  noOffloors: number
}
