import { DISCOUNT_TYPE, DiscountComponent, DiscountInfo, IClientDiscountManager, IProductDiscountSetting, ItemBillingDiscountInfo } from "../../../all-types";

class ProductDiscountCalc {

    billDiscount:ItemBillingDiscountInfo;
    discountSettings:IProductDiscountSetting;
    parentDiscountSettings: IClientDiscountManager;

    constructor(psettings: IClientDiscountManager, settings: IProductDiscountSetting, 
        billDiscount:ItemBillingDiscountInfo = {
            amountAfterDiscount: 0,
            amountBeforeDiscount: 0,
            discountAmount: 0,
            discountsInfo: [],
            isDiscountApplied: false,
        }) {
        this.parentDiscountSettings = psettings;
        this.discountSettings = settings;
        this.billDiscount = billDiscount;
    }


    getDiscountRecord = (amount: number, discount: DiscountComponent, index = 0): DiscountInfo => {
        let discountAmount = 0;
        if(discount.discountType === DISCOUNT_TYPE.PERCENTAGE) {
            discountAmount = Number(((amount/100) * discount.applicableDiscount).toFixed(4));
        } else {// CASH
            discountAmount = Number(amount - discount.applicableDiscount);
        }
        return {
            discountSno: index,
            discountComponent: discount,
            amountBeforeDiscount: amount,
            discountAmount: discountAmount,
            amountAfterDiscount: amount - discountAmount //amountBeforeTax
        }
    }

     getDiscountCompsFromParent = (appliedComponent: string[] ) => {
        let discountComp:DiscountComponent[] = [];
        try {
            const {activeDiscounts} = this.parentDiscountSettings;
            appliedComponent?.forEach((discountId) => {
                if(activeDiscounts && activeDiscounts[discountId] && activeDiscounts[discountId].isActive) {
                    discountComp.push(activeDiscounts[discountId]);
                 }
            });
            discountComp = discountComp.sort((a,b) => a.applicableDiscount - b.applicableDiscount); 
        } catch(e) {
            console.error(e);
        }
        return discountComp;
    }

    applyDiscountByProductSettings = (amount: number) => {
        try {
            this.billDiscount.amountBeforeDiscount = amount;
            const {isDiscountsApplicable, considerStrictProductSettings} = this.parentDiscountSettings;
            if(isDiscountsApplicable && considerStrictProductSettings ) {
                const {enableStrictRules, isDiscountApplicable , enableProductDiscount, appliedComponent} = this.discountSettings;
                if(enableStrictRules && isDiscountApplicable) {
                    if(enableProductDiscount && appliedComponent?.length > 0) {
                        this.billDiscount.isDiscountApplied = true;
                        let calAmount = amount;
                        const discountComp:DiscountComponent[] = this.getDiscountCompsFromParent(appliedComponent);
                        discountComp?.forEach((discount, index) => {
                            const discountInfo = this.getDiscountRecord(calAmount, discount, index);
                            calAmount = discountInfo.amountAfterDiscount;
                            this.billDiscount.discountAmount += discountInfo.discountAmount;
                            this.billDiscount.discountsInfo.push(discountInfo);
                        });
                        this.billDiscount.amountAfterDiscount = calAmount;
                    } else {
                        this.billDiscount.isDiscountApplied = false;
                        this.billDiscount.amountAfterDiscount = amount;
                    }
                }
            }
        } catch(e) {
            console.error(e);
        }
    }
}

export { ProductDiscountCalc };
