import { useEffect, useMemo, useState } from "react";
import { BillType, FullBillingInfo } from "../all-types";
import { useAppSelector } from "../redux-store/hooks";


const DEFAULT_FINAL_BILLING_INFO:FullBillingInfo = {
    totalItemsCostRaw: 0,
    totalDiscountIssued: 0,
    totalTaxCharged: 0,
    TaxChargeSheetView: {},
    finalBillAmount: 0
}


const calculateFinalTotal = (fullBill: BillType) => {
    const tempBillinInfo = {...DEFAULT_FINAL_BILLING_INFO};
    try {
        const {items, billingInfo} = fullBill;

        items?.forEach((item) => {
            tempBillinInfo.totalItemsCostRaw += item.billingInfo.rawAmount;
            tempBillinInfo.totalDiscountIssued += item.billingInfo.totalDiscountGiven;
            tempBillinInfo.totalTaxCharged += item.billingInfo.totalTaxCharged;
            if(item.billingInfo.totalTaxCharged > 0) {
                item.billingInfo.tax.taxInfo?.forEach(tax => {
                    const taxName = tax.taxComponent.taxName;
                    if(tempBillinInfo.TaxChargeSheetView[taxName]) {
                        tempBillinInfo.TaxChargeSheetView[taxName] += tax.taxAmount;
                    } else {
                        tempBillinInfo.TaxChargeSheetView[taxName] = tax.taxAmount;
                    }
                });
            }
            tempBillinInfo.finalBillAmount = Number((tempBillinInfo.finalBillAmount + item.billingInfo.finalAmount).toFixed(2)) ;
        });
    } catch(e) {
        console.error(e);
    }
    return tempBillinInfo;
}

const useBillingInfo = (currentTab: string) => {
    const bill = useAppSelector(state => state.billing.activeBills[currentTab].bill);
    const [fullBill, setFullBill] = useState(bill);

    useEffect(() => {
        const bInfo = calculateFinalTotal(bill);
        setFullBill({...bill, billingInfo: bInfo});
    },[bill.items, bill.modeOfPayment]);

    return {fullBill};
}

export {useBillingInfo};