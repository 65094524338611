import {
    Box,
    Grid,
    Modal,
    Stack,
    SwipeableDrawer
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useState } from 'react';
import { BottomBarActionBtn, ModalBox } from '../../../components';
import { AssignFloorForm, BottomSaveBar, CreatePlaceForm, CreateTableForm, FloorViewer, PlacesViewer, TablesViewer } from '../components';
import { STYLE_CONSTANTS } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { updateClientInfo } from '../../../services';

const StyledMainContent = styled(Box)({
    flex: 1,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
});

const StyledTableGrid = styled(Grid)({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 20,
});

const StyledTable = styled(Box)({
    padding: 20,
    borderRadius: 20,
    textAlign: 'center',
    fontWeight: 600,
    color: '#A0A0A0',
});

const StyledLegend = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
});

const StyledLegendItem = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: 10,
});

const StyledColorBox = styled(Box)({
    width: 20,
    height: 20,
    borderRadius: 5,
});




const SCROLL_STYLES = {
    overflowY: 'auto',
    borderRight: `1px solid black`,
    borderLeft: `1px solid black`,
    scrollbarWidth: 'thin',
    position: "relative",
    overflowX: "hidden",
}

const ClientTablesSettings = () => {
    const [openEdit, setOpenEdit] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [floorOpen, setFloorOpen] = React.useState(false);
    const STANDARD_HEADER_HEIGHT = useAppSelector(state => state.ui.STANDARD_HEADER_HEIGHT);
    const OCCUPIED_HEIGHT = STANDARD_HEADER_HEIGHT + STYLE_CONSTANTS.SETTING_BOTTOM_BAR_HEIGHT;
    const VIEW_HEIGHT = `calc(100vh - ${OCCUPIED_HEIGHT}px)`

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFloorOpen = () => setFloorOpen(true);
    const handleFloorClose = () => setFloorOpen(false);

    const dispatcher = useAppDispatch();
    const clientInfo = useAppSelector(state => state.clientInfo.data);

    const saveAllTableData = async () => {
        try {
            await dispatcher(updateClientInfo({
                clientId: clientInfo._id,
                body: clientInfo
            }));
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Box display="flex" flexDirection={"column"} flex={1}>
            <Stack flexDirection={"row"} flex={1}>
                <Stack flex={1} height={VIEW_HEIGHT} sx={{
                    overflowY: 'auto',
                    borderRight: `1px solid black`,
                    borderLeft: `1px solid black`,
                    scrollbarWidth: 'thin',
                    position: "relative",
                    overflowX: "hidden",
                    minWidth: "150px"
                }}>
                    <PlacesViewer />
                </Stack>
                <Stack flex={8} height={VIEW_HEIGHT} sx={{...SCROLL_STYLES}}>
                    <TablesViewer/>
                    {/* <StyledMainContent>
                        <Box>
                            <StyledTableGrid>
                                <StyledTable className="reserved">
                                    T1
                                    <br />
                                    Jhon ciena 11:30am
                                </StyledTable>
                                <StyledTable className="checked-in">
                                    T2
                                    <br />
                                    Checked-in
                                </StyledTable>
                                <StyledTable className="reserved">
                                    T3
                                    <br />
                                    Kathryn 11:30pm
                                </StyledTable>
                                <StyledTable className="checked-in">
                                    T4
                                    <br />
                                    Checked-in
                                </StyledTable>
                                <StyledTable className="free">
                                    T5
                                    <br />
                                    Free
                                </StyledTable>
                                <StyledTable className="checked-in">
                                    T6
                                    <br />
                                    Checked-in
                                </StyledTable>
                                <StyledTable className="reserved">
                                    T7
                                    <br />
                                    Donald 11:30am
                                </StyledTable>
                                <StyledTable className="free">
                                    T8
                                    <br />
                                    Free
                                </StyledTable>
                                <StyledTable className="free">
                                    T9
                                    <br />
                                    Free
                                </StyledTable>
                            </StyledTableGrid>
                        </Box>
                        <StyledLegend>
                            <StyledLegendItem>
                                <StyledColorBox className="free"></StyledColorBox>
                                Free: 3
                            </StyledLegendItem>
                            <StyledLegendItem>
                                <StyledColorBox className="reserved"></StyledColorBox>
                                Reserved: 3
                            </StyledLegendItem>
                            <StyledLegendItem>
                                <StyledColorBox className="checked-in"></StyledColorBox>
                                Checked-in: 3
                            </StyledLegendItem>
                        </StyledLegend>
                    </StyledMainContent> */}
                </Stack>
                <Stack flex={1} height={VIEW_HEIGHT} sx={{
                    overflowY: 'auto',
                    borderRight: `1px solid black`,
                    borderLeft: `1px solid black`,
                    scrollbarWidth: 'thin',
                    position: "relative",
                    overflowX: "hidden",
                    minWidth: "150px"

                }}>
                    <FloorViewer />
                </Stack>
            </Stack>

            <SwipeableDrawer
                anchor={"right"}
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                onOpen={() => setOpenEdit(true)}>
                <Grid container width={600} mt={"64px"}>
                    <Grid item xs={12} md={12}>
                        <CreateTableForm />
                    </Grid>
                </Grid>
            </SwipeableDrawer>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <ModalBox>
                    <CreatePlaceForm />
                </ModalBox>
            </Modal>

            <Modal
                open={floorOpen}
                onClose={handleFloorClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <ModalBox>
                    <AssignFloorForm />
                </ModalBox>
            </Modal>

            <BottomSaveBar onSave={saveAllTableData} childrenActions={
                <>
                    <BottomBarActionBtn variant='contained' color='secondary' onClick={() => setOpenEdit(true)}>Create Table</BottomBarActionBtn>
                    <BottomBarActionBtn variant='contained' color='secondary' onClick={handleOpen}>Create Place</BottomBarActionBtn>
                    <BottomBarActionBtn variant='contained' color='secondary' onClick={handleFloorOpen}>Assign Floor</BottomBarActionBtn>
                </>
            } />
        </Box>
    );
};

export { ClientTablesSettings };
