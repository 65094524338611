import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uuid from "react-uuid";
import { STATUS } from '../../../constants'
import {
  getClientInfo,
  updateClientInfo,
  updatePaytmCardReaderSettings,
  updatePrinterSettings,
} from '../../../services'
import { CLEINT_INFO_INITIAL_DATA } from './inital-data'
import {
  ClientInfoType,
  DiscountComponent,
  ITables,
  TaxComponent,
} from '../../../all-types'


const initialState: {
  data: ClientInfoType
  status: string
  error: string
} = {
  data: CLEINT_INFO_INITIAL_DATA,
  status: STATUS.EMPTY,
  error: '',
}

export const ClientInfoSlice = createSlice({
  name: 'clientInfo',
  initialState,
  reducers: {
    setClientInfo: (state, action: PayloadAction<ClientInfoType>) => {
      state.data = action.payload
    },
    addTaxComponent: (state, action: PayloadAction<TaxComponent>) => {
      let taxList = state.data.taxManager?.activeTaxList
      const taxComp = action.payload
      if (!(Object.keys(taxList).length > 0)) {
        taxList = {}
      }
      taxList[taxComp.taxId] = taxComp
      state.data.taxManager = {
        ...state.data.taxManager,
        activeTaxList: taxList,
      }
    },
    addDiscountComponent: (state, action: PayloadAction<DiscountComponent>) => {
      let discountList = state.data.discountManager?.activeDiscounts
      const discountComp = action.payload
      if (!(Object.keys(discountList).length > 0)) {
        discountList = {}
      }
      discountList[discountComp.discountId] = discountComp
      state.data.discountManager = {
        ...state.data.discountManager,
        activeDiscounts: discountList,
      }
    },
    clearClientInfo: (state, action) => {
      state.data = CLEINT_INFO_INITIAL_DATA
    },
    setConnectionInfo(state, action) {
      if (action.payload.productId && action.payload.vendorId) {
        state.data.printerSettings.productId = action.payload.productId
        state.data.printerSettings.vendorId = action.payload.vendorId
      }
      state.data.printerSettings.connectionSettings = {
        ...state.data.printerSettings.connectionSettings,
        ...action.payload,
      }
      state.data.printerSettings.isPrinterConfigured = false
    },
    setIsPrinterConnected(state, action) {
      state.data.printerSettings.isPrinterConfigured = action.payload
    },
    addPlace(state, action) {
      state.data.tableManager.places.push(action.payload);
    },
    removePlace(state, action) {
      const tempPlaces = state.data.tableManager.places.filter(
        (p) => p !== action.payload
      )
      state.data.tableManager.places = tempPlaces
    },
    assignFloor(state, action) {
      state.data.tableManager.noOffloors = action.payload;
    },
    addRestaurantTable(state, action:PayloadAction<ITables>) {
      const {tableName, tableNo, floorNo} = action.payload;
      const uniqueTableName = tableName.concat(String(tableNo)).concat(String(floorNo));
      state.data.tableManager.tables[uniqueTableName] = {...action.payload, tableId: uuid()};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientInfo.pending, (state) => {
        state.status = STATUS.LOADING
      })
      .addCase(getClientInfo.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS
        state.data = action.payload
      })
      .addCase(getClientInfo.rejected, (state, action) => {
        state.status = STATUS.FAILED
        state.error = action.error.message || 'Something went wrong'
      })
      .addCase(updatePrinterSettings.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(updatePaytmCardReaderSettings.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(updateClientInfo.fulfilled, (state, action) => {
        state.data = action.payload
      })
  },
})

export const {
  setClientInfo,
  clearClientInfo,
  addTaxComponent,
  addDiscountComponent,
  addPlace,
  removePlace,
  assignFloor,
  addRestaurantTable
} = ClientInfoSlice.actions
