import { Button, Checkbox, MenuItem, Paper, Select, SelectChangeEvent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import uuid from "react-uuid";
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { ChangeEvent, useState } from 'react';
import { DISCOUNT_TYPE, DiscountComponent, TAX_TYPE, TaxComponent } from '../../../all-types';
import { addDiscountComponent, addTaxComponent } from '../../../redux-store';


const defaultDiscountOptions: DiscountComponent = {
    discountId: "",
    discountName: "",
    applicableDiscount: 0,
    discountType: DISCOUNT_TYPE.PERCENTAGE,
    isActive: false,
    startDate: '',
    endDate: ''
}

const ClientDiscountList = () => {
    const activeDiscounts = useAppSelector(state => state.clientInfo.data.discountManager?.activeDiscounts);
    const dispatcher = useAppDispatch();
    const [newDiscount, setNewDiscount] = useState<DiscountComponent>(defaultDiscountOptions);

    const addDiscountsToList = async () => {
        try {
            await dispatcher(addDiscountComponent({
                ...newDiscount, discountId: uuid(),
                startDate: String(new Date().getTime())
            }));
            setNewDiscount(defaultDiscountOptions);
        } catch (e) {
            console.error(e);
        }
    }

    const handlDiscountTypeChange = (event: SelectChangeEvent<DISCOUNT_TYPE>) => {
        const { value } = event.target;
        setNewDiscount({ ...newDiscount, discountType: value as DISCOUNT_TYPE });
    };

    const handleIsActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewDiscount({ ...newDiscount, isActive: event.target.checked });
    };


    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setNewDiscount({ ...newDiscount, [name]: value });
    };

    return (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Discount ID</TableCell>
                        <TableCell>Discount Name</TableCell>
                        <TableCell>Applicable Discount (%/Cash)</TableCell>
                        <TableCell>Discount Type</TableCell>
                        <TableCell>Is Active</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(activeDiscounts)?.map((discount) => (
                        <TableRow key={discount.discountId}>
                            <TableCell>{discount.discountId}</TableCell>
                            <TableCell>{discount.discountName}</TableCell>
                            <TableCell>{discount.applicableDiscount}</TableCell>
                            <TableCell>{discount.discountType}</TableCell>
                            <TableCell>{discount.isActive ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{discount.startDate}</TableCell>
                            <TableCell>{discount.endDate}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            Auto
                        </TableCell>
                        <TableCell>
                            <TextField
                                name="discountName"
                                value={newDiscount.discountName}
                                onChange={handleInputChange}
                                placeholder="Enter Discount Name"
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                name="applicableDiscount"
                                value={newDiscount.applicableDiscount}
                                onChange={handleInputChange}
                                placeholder="Enter Applicable Discount"
                            />
                        </TableCell>
                        <TableCell>
                            <Select
                                value={newDiscount.discountType}
                                onChange={handlDiscountTypeChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={DISCOUNT_TYPE.PERCENTAGE}>PERCENTAGE</MenuItem>
                                <MenuItem value={DISCOUNT_TYPE.CASH}>CASH</MenuItem>
                            </Select>
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                name="isActive"
                                checked={newDiscount.isActive}
                                onChange={handleIsActiveChange}
                            />
                        </TableCell>
                        <TableCell>
                            Auto
                        </TableCell>
                        <TableCell>
                            Auto
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Stack alignItems={"flex-end"}>
                <Button variant='contained' sx={{
                    width: 200, height: 60,
                    margin: 2
                }}
                    onClick={addDiscountsToList}>Add</Button>
            </Stack>
        </TableContainer>
    );
};

export { ClientDiscountList };
