import {
  IClientTaxSettings,
  IProductTaxSetting,
  ItemBillingTaxInfo,
  TAX_TYPE,
  TaxComponent,
} from '../../../all-types'

class ProductTaxCalc {
  billTax: ItemBillingTaxInfo
  taxSettings: IProductTaxSetting
  parentTaxSettings: IClientTaxSettings

  constructor(
    parentTaxSettings: IClientTaxSettings,
    taxSettings: IProductTaxSetting,
    billTax: ItemBillingTaxInfo = {
      isTaxApplied: true,
      taxInfo: [],
      amountBeforeTax: 0,
      taxAmount: 0,
      amountAfterTax: 0,
    }
  ) {
    this.parentTaxSettings = parentTaxSettings
    this.taxSettings = taxSettings
    this.billTax = billTax
    this.parentTaxSettings.activeTaxList
  }

  getTaxCompsFromParent = (appliedComponent: string[]): TaxComponent[] => {
    let taxComp: TaxComponent[] = []
    try {
      const { activeTaxList } = this.parentTaxSettings
      appliedComponent?.forEach((taxId) => {
        if (activeTaxList && activeTaxList[taxId]) {
          taxComp.push(activeTaxList[taxId])
        }
      })
    } catch (e) {
      console.error(e)
    }
    return taxComp
  }

  getDefaultTaxCompoenents = () => {
    let defaultTaxRecords: TaxComponent[] = []
    try {
      const { activeTaxList } = this.parentTaxSettings
      if (
        activeTaxList &&
        Object.values(activeTaxList) &&
        Object.values(activeTaxList).length > 0
      ) {
        const taxRecords = Object.values(activeTaxList)
        defaultTaxRecords = taxRecords.filter(
          (trecord) => trecord.isDefault === true
        )
      }
    } catch (e) {
      console.error(e)
    }
    return defaultTaxRecords
  }

  addDefaultTaxCompoenents = (taxCalcRecords: TaxComponent[]) => {
    try {
      const defaultTaxRecords = this.getDefaultTaxCompoenents()
      defaultTaxRecords?.forEach((trecords) => {
        const isExists = taxCalcRecords.some((t) => t.taxId === trecords.taxId)
        if (!isExists) {
          taxCalcRecords.push(trecords)
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  addTaxInBill = (taxCalcRecords: TaxComponent[], amount: number) => {
    try {
      let totalTaxAmount = 0
      taxCalcRecords?.forEach((tax) => {
        const taxRecord = {
          taxComponent: tax,
          taxableAmount: amount,
          taxAmount: 0,
        }
        if (tax.taxType === TAX_TYPE.PERCENTAGE) {
          taxRecord.taxAmount = Number(
            ((amount / 100) * tax.applicableTax).toFixed(4)
          )
        } else {
          taxRecord.taxAmount = Number(amount - tax.applicableTax)
        }
        totalTaxAmount += taxRecord.taxAmount
        this.billTax.taxInfo.push(taxRecord)
      })

      this.billTax.isTaxApplied = true
      this.billTax.taxAmount = totalTaxAmount
      this.billTax.amountAfterTax = amount + totalTaxAmount
    } catch (e) {
      console.error(e)
    }
  }

  applyProductTax = (amount: number) => {
    try {
      let taxCalcRecords: TaxComponent[] = [];
      this.billTax.amountBeforeTax = amount
      const {
        isTaxApplicable,
        applyDefaultTax,
        considerStrictProductSettings,
      } = this.parentTaxSettings
      if (isTaxApplicable) {
        if (!considerStrictProductSettings && !applyDefaultTax) {
            this.billTax.isTaxApplied = false
            this.billTax.amountAfterTax = amount
            return;
        }

        let canAddDefault = true;
        if (considerStrictProductSettings) {
          const {
            enableStrictRules,
            isTaxApplicable,
            applyDefaultTax,
            enableProductTax,
            appliedComponent,
          } = this.taxSettings
          if (enableStrictRules && isTaxApplicable) {
            if (enableProductTax) {
              taxCalcRecords = this.getTaxCompsFromParent(appliedComponent)
            }
            if (applyDefaultTax) {
              this.addDefaultTaxCompoenents(taxCalcRecords)
            } else {
                canAddDefault = false;
            }
          } else if(isTaxApplicable && applyDefaultTax){
            canAddDefault = true;
          } else {
            this.billTax.isTaxApplied = false
            this.billTax.amountAfterTax = amount
            return
          }
        }

        if (applyDefaultTax && canAddDefault) {
          this.addDefaultTaxCompoenents(taxCalcRecords)
        }


      } else {
        this.billTax.isTaxApplied = false
        this.billTax.amountAfterTax = amount
        return
      }

      if (taxCalcRecords.length > 0) {
        this.addTaxInBill(taxCalcRecords, amount)
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export { ProductTaxCalc }
