import { Button, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useBillingInfo } from "../../../hooks";
import { useAppDispatch } from "../../../redux-store/hooks";
import { createNewBill } from "../../../services";
import { BillConfimPopup } from "../components";
import { BillProcessDrawer } from "./billing-process-drawer";


interface CartPanelItemDetailsProps {
    currentTab: string;
    showBillingScreen: () => void;
    goBack?: () => void;
    isSummary: boolean
}

const CartPanelItemDetails: FC<CartPanelItemDetailsProps> = ({ currentTab, showBillingScreen, goBack, isSummary }) => {
    const { fullBill } = useBillingInfo(currentTab);
    const dispatcher = useAppDispatch();
    const [openConfimPopup, setConfimPopup] = useState(false);
    const handleOpenConfimPopup = () => setConfimPopup(true);
    const handleCloseConfimPopup = () => setConfimPopup(false);
    const [loadBilling, setLoadBilling] = useState(true);

    const [openShareBillDrawer, setOpenShareBillDrawer] = useState(false);

    const toggleShareBillDrawer = (newOpen: boolean) => {
        setOpenShareBillDrawer(newOpen);
    };

    const proceedForBilling = () => {
        try {

        } catch (e) {
            console.error(e);
        } finally {
            showBillingScreen();
        }
    }


    const startCreatingBillProcess = async () => {
        try {
            setLoadBilling(true);
            handleCloseConfimPopup();
            toggleShareBillDrawer(true);
            const resp = await dispatcher(createNewBill({ bill: fullBill }));
            console.log(resp);
        } catch (e) {
            console.error(e);
        } finally {
            setLoadBilling(false);
        }
    }

    return (
        <Stack flex={1} width={"100%"} justifyContent={"flex-end"} p={2}>
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography variant="body1"  >
                    Total Items
                </Typography>
                <Typography variant="body1"  >
                    {fullBill.items?.length}
                </Typography>
            </Stack>
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography variant="body1"  >
                    Subtotal
                </Typography>
                <Typography variant="body1"  >
                    {fullBill.billingInfo.totalItemsCostRaw}
                </Typography>
            </Stack>

            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography variant="body1"  >
                    Discount
                </Typography>
                <Typography variant="body1"  >
                    {fullBill.billingInfo.totalDiscountIssued}
                </Typography>
            </Stack>

            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography variant="body1"  >
                    Tax
                </Typography>
                <Typography variant="body1"  >
                    {fullBill.billingInfo.totalTaxCharged.toFixed(2)}
                </Typography>
            </Stack>

            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography variant="h6"  >
                    Final Amount
                </Typography>
                <Typography variant="h6"  >
                    {fullBill.billingInfo.finalBillAmount}
                </Typography>
            </Stack>
            {isSummary ? (
                <Stack flexDirection={"row"} justifyContent={"space-between"} gap={1}>
                    <Button variant="contained" color="error" onClick={goBack} fullWidth sx={{ height: 50 }}>Back</Button>
                    <Button variant="contained" color="success"
                        disabled={fullBill.modeOfPayment === ""}
                        fullWidth sx={{ height: 50 }} onClick={handleOpenConfimPopup}>Place Order</Button>
                </Stack>
            ) : (
                <Stack flexDirection={"row"} justifyContent={"space-between"} gap={1}>
                    <Button variant="contained" color="error" fullWidth sx={{ height: 50 }}>Hold/Close</Button>
                    <Button variant="contained" color="success" fullWidth sx={{ height: 50 }} onClick={proceedForBilling}>Proceed</Button>
                </Stack>

            )}

            <BillConfimPopup handleClose={handleCloseConfimPopup} onConfirm={startCreatingBillProcess} open={openConfimPopup} />
            <BillProcessDrawer isLoading={loadBilling} open={openShareBillDrawer} toggleDrawer={toggleShareBillDrawer} />
        </Stack>
    )
}

export { CartPanelItemDetails };

