import * as React from 'react';
import { Tabs, Tab, Stack, useTheme } from '@mui/material';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DiscountIcon from '@mui/icons-material/Discount';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import TableBarIcon from '@mui/icons-material/TableBar';

import { Printer } from '../printer';
import { ClientDiscountSettings, ClientInfo, ClientTablesSettings, ClientTaxSettings } from '../client-info';

const Settings = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const tabContent = [
        <Stack key="profile" bgcolor={"white"}>
            <ClientInfo />
        </Stack>,
        <Stack key="printer">
            <Printer />
        </Stack>,
        <div key="display"><h2>Display Settings</h2></div>,
        <Stack key="tax-settings" sx={{ backgroundColor: "white" }}>
            <ClientTaxSettings />
        </Stack>,
        <Stack key="discount-settings">
            <ClientDiscountSettings />
        </Stack>,
        <Stack key="table-settings" flex={1}>
            <ClientTablesSettings />
        </Stack>
    ];

    return (
        <Stack flexDirection={"row"}  sx={{backgroundColor: theme.palette.background.default}}>
            <Tabs value={value} onChange={(e, v) => setValue(v)} orientation='vertical' 
            sx={{backgroundColor: theme.palette.background.default, maxWidth: 100, minWidth: 100, height: "100%"}}>
                <Tab icon={<ManageAccountsIcon fontSize='large' />} label="Profile" aria-label="Profile Settings" />
                <Tab icon={<LocalPrintshopIcon fontSize='large' />} label="Printer" aria-label="Printer Settings" />
                <Tab icon={<PersonalVideoIcon fontSize='large' />} label="Display" aria-label="Display Settings" />
                <Tab icon={<DocumentScannerIcon fontSize='large' />} label="Tax" aria-label="Tax Settings" />
                <Tab icon={<DiscountIcon fontSize='large' />} label="Discounts" aria-label="Discounts Settings" />
                <Tab icon={<TableBarIcon fontSize='large' />} label="Tables" aria-label="Table Settings" />
            </Tabs>
            {tabContent[value]}
        </Stack>
    );
};

export { Settings };
