import {
    Autocomplete,
    Box,
    Button, CircularProgress, Grid, MenuItem, Select, Stack, Tab, TextField, Typography, useTheme
} from '@mui/material';
import React, { FC, useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import uuid from "react-uuid";
import { CURRENCY, IProductRate, ProductType } from '../../../all-types';
import { NO_IMAGE_URL } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../redux-store/hooks';
import { createProduct, getFileUploadURL } from '../../../services';
import { ProductDiscount } from '../components';
import { ProductRates } from '../components/product-rates';
import { ProductTax } from '../components/product-tax';


const getDefaultProduct = (): ProductType => {
    const newID = uuid();
    return {
        productId: newID,
        productCode: 12345,
        name: "Sample Product",
        description: "This is a sample product",
        categoryId: "",
        category: "",
        image: NO_IMAGE_URL,
        defaults: new Map(),
        rateCard: new Map(),
        isInStock: true,
        currency: CURRENCY.RUPEE,
        tax: {
            isTaxApplicable: false,
            enableStrictRules: false,
            enableProductTax: false,
            applyDefaultTax: false,
            appliedComponent: []
        },
        discounts: {
            enableStrictRules: false,
            isDiscountApplicable: false,
            enableProductDiscount: false,
            allowTotalBillDiscount: false,
            applyDefaultDiscount: false,
            appliedComponent: [],
        }
    }
}

interface AddPopupProps {
    exit?: () => void;
}

const AddProductPopup: FC<AddPopupProps> = ({ exit }) => {
    const theme = useTheme();
    const dispatcher = useAppDispatch();
    const categories = useAppSelector(state => state.categories.data);
    const [loading, setLoading] = useState(false);
    const [imgLoading, setImageLoading] = useState(false);
    const [value, setValue] = React.useState('1');
    const [product, setProduct] = useState<ProductType>(getDefaultProduct());
    const addRateCardItems = (newRateCard: IProductRate, defaults: IProductRate) => {
        setProduct({ ...product, rateCard: newRateCard, defaults })
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const createNewProduct = async () => {
        try {
            setLoading(true);
            const newProduct: ProductType = {
                ...product,
                rateCard: Object.fromEntries(product.rateCard),
                defaults: Object.fromEntries(product.defaults)
            }
            const resp = await dispatcher(createProduct(newProduct));
            if (resp && resp.type) {
                setProduct(getDefaultProduct()); // UPdates the product list
                if (exit && resp.type.indexOf("fulfilled") != -1) {
                    exit();
                }
            }
            //TODO: Add Toast
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    const uploadImageToBucket = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            setImageLoading(true);
            const newFile = event.target.files?.[0];
            if (newFile) {
                const imageURL = await getFileUploadURL(newFile);
                setProduct({ ...product, image: imageURL });
            }
            //TODO: Add Toast
        } catch (e) {
            console.log(e);
        } finally {
            setImageLoading(false);
        }
    }

    if (loading) {
        return (
            <Stack sx={{
                height: "80vh", flex: 1, display: 'flex',
                justifyContent: 'center', alignItems: "center"
            }}>
                <CircularProgress size={100} />
            </Stack>
        )
    }

    return (
        <Stack component="form" gap={2} p={2}>
            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <AddCircleOutlineIcon color='primary' />
                    <Typography variant="h5">Add Product</Typography>
                </Stack>
                <Button variant='contained' onClick={createNewProduct}
                    style={{ height: 50, width: 100 }}>Save</Button>
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Stack height={230}
                        sx={{
                            backgroundImage: `url(${product?.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            borderRadius: 2
                        }}
                        bgcolor={theme.palette.background.default}
                        justifyContent={"flex-end"}>
                        <LoadingButton
                            component="label"
                            color='primary'
                            variant='contained'
                            loading={imgLoading}
                            loadingIndicator="Uploading…"
                            sx={{ height: 50 }}
                            startIcon={<CloudUploadIcon />}

                        >
                            {product?.image ? "Change Image" : "Upload Image"}
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={uploadImageToBucket}
                            />
                        </LoadingButton>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack gap={1}>
                        <TextField
                            label="Name"
                            value={product.name}
                            onChange={(event) =>
                                setProduct({ ...product, name: event.target.value })}
                        />
                        <TextField
                            label="Product Code"
                            type="number"
                            value={product.productCode}
                            onChange={(event) =>
                                setProduct({
                                    ...product,
                                    productCode: parseInt(event.target.value, 10)
                                })}
                        />

                        <Select
                            fullWidth
                            value={product.isInStock ? "stock" : "outOfStock"}
                            onChange={(event) =>
                                setProduct({
                                    ...product,
                                    isInStock: event.target.value === "stock"
                                })}
                            required
                            placeholder='Is in Stock'
                        >
                            <MenuItem value="stock">
                                <em>Available</em>
                            </MenuItem>

                            <MenuItem value="outOfStock">
                                <em>Not Available</em>
                            </MenuItem>

                        </Select>
                        <Stack>
                            <Autocomplete
                                fullWidth
                                options={categories}
                                getOptionLabel={(option) => option.categoryName} // Display the category name
                                value={categories.find((category) => category.categoryId === product.categoryId) || null}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        let val = ""
                                        const cat = categories.find((category) =>
                                            category.categoryId === newValue.categoryId);
                                        if (cat) {
                                            val = cat.categoryName;
                                        }
                                        setProduct({ ...product, categoryId: newValue.categoryId, category: val });
                                    } else {
                                        setProduct({ ...product, categoryId: '' });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select a Category"
                                        required
                                    />
                                )}
                                isOptionEqualToValue={(option, value) => option.categoryId === value.categoryId}
                            />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Pricing" value="1" />
                                <Tab label="Tax" value="2" />
                                <Tab label="Discount/Offer" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <ProductRates allRate={product.rateCard} setAllRate={addRateCardItems} />
                        </TabPanel>
                        <TabPanel value="2">
                            <ProductTax data={product.tax} onChange={(data) => setProduct({ ...product, tax: data })} />
                        </TabPanel>
                        <TabPanel value="3">
                            <ProductDiscount data={product.discounts} onChange={(data) => setProduct({ ...product, discounts: data })} />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Stack>
    );
};

export { AddProductPopup };

