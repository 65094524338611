import { useState, useEffect, useCallback } from 'react';
import { initOfflineDB } from '../offline-db';

const useOfflineDb = () => {
  const [isDbReady, setIsDbReady] = useState(false);

  const initializeDb = useCallback(async () => {
    const isDbInitialized = sessionStorage.getItem('isDbReady');
    if (!isDbInitialized) {
      try {
        await initOfflineDB();
        sessionStorage.setItem('isDbReady', 'true');
        setIsDbReady(true);
      } catch (error) {
        console.error('Error initializing database:', error);
        setIsDbReady(false);
      }
    } else {
      setIsDbReady(true);
    }
  }, []);

  useEffect(() => {
    initializeDb();
  }, [initializeDb]);

  return { isDbReady };
};

export { useOfflineDb };